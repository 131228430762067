
:root{
    --primary: dodgerblue;
    --blue-alternate: #1aadfb 
}
.billing-css .text-primary, 
.billing-css a:not(.text-danger){
    color: var(--primary) !important;
}
.billing-css .btn-outline-primary{
    border-color: var(--primary);
    color: var(--primary);
    border-width: 2px;
    font-weight: 599;
}
.billing-css .btn-outline-primary:hover{
    background-color: var(--primary);
    border-color: var(--primary);
}

.billing-css .table td{
    padding: 1.25rem;
    vertical-align: bottom;
}  

.billing-css .table .fa{
    font-size: 1.25rem;
    position: relative;
    top: 1px;
}

.billing-css .bill-card{
    width:40px;
    margin-right: 20px;
}

.billing-css .billing-tabs a{
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    /* margin-right: .5rem; */
    color: var(--blue-alternate);
}

/* .billing-tabs a:hover, */
.billing-css .billing-tabs a.active{
    border: none;
    border-bottom: 5px solid var(--blue-alternate) !important
}

.billing-css #all span{
    display: inline-block;
    margin-bottom: .5rem;
}

.billing-css .rounded-btn{
    border-radius: 30px;
}
.billing-css .hidecardatrr{
    display: none !important;
}



.billing-css .opensright:before,
.billing-css .opensright:after{
    content: none;
}


#monthlyUsage .table tr th:first-child,
#monthlyUsage .table tr th{
    width: calc(100% / 4) !important;
}


