.yr-automation-uodate .kt-checkbox-list ul{
  list-style: none;
  padding-left: 15px;
}
.yr-automation-uodate .close {
position: absolute;
right: -6px;
top: -6px;
background-image: linear-gradient(45deg,#03bfa4,#0797a9);
padding: 4px;
border: 3px solid;
border-radius: 50%;
width: 25px;
height: 25px;
font-size: 80%;
color: #fff;
opacity: 1;
font-weight: normal;
line-height: 12px;
}
.yr-automation-uodate .yr-preview{
margin-top:0px;
max-width: 410px;
}
.yr-automation-uodate .ui.pointing.secondary.menu {
border-bottom: transparent;
font-size: 1.2em;
min-height: 47px;
}
.yr-automation-uodate .yr-cancelbtn span{
padding: 5px 20px;
}
.yr-automation-uodate button.btn.yr-submitbtn {
height: auto;
min-width: 100px;
}
.yr-automation-uodate .ui.secondary.pointing.menu .active.item {
border-color: #068bb1;
border-bottom-width: 4px;
color: #7f828e;
font-weight: 400;
}

.yr-automation-uodate .ui.segment.active.tab {
border: 0px;
box-shadow: none;
padding: 0;
}

.yr-automation-uodate .ui.secondary.menu .item {
font-size: 16px;
padding-bottom: 10px !important;
font-weight: 400;
color: #7f828e;
}
.yr-automation-uodate .yr-ansDiv {
  border: 1px solid #1da0ff;
  padding: 10px;
  margin-bottom: 10px;
  resize: none;
 outline: 0!important;
}
.yr-automation-uodate .kt-checkbox-list .kt-checkbox:last-child {
margin-bottom: 18px;
}
.yr-automation-uodate .kt-checkbox-list ul ul {
list-style: outside !important;
padding-left: 35px;
}
.yr-automation-uodate .add-gradient {
text-align: center;
font-size: 14px;
}
.yr-addButtonBox{
color: #7f828e !important;
}
.yr-btnHover {
  position: relative;
}
.yreditbtn {
  width: 100%;
  background: #000000;
  color: #fff;
  border-radius: 2rem;
  line-height: 35px;
  height: 38px;
  margin-bottom: 10px;
  position: absolute;
  left: 0;
  display: none;
}
.yr-btnHover:hover .yreditbtn {
  display: inline-block;
  text-align: center;
}
.yr-body-bottom {
  max-height: 200px;
  overflow: auto;
  padding: 0 10px;
}

.yr-customRes{
  background: #efefef;
    padding-right: 40px;
    display: inline-block;
    width: 100%;
    padding: 8px 38px 8px 10px;
    position: relative;
}
.yr-btnArea-action{
  position: absolute;
  right: 5px;
  top: 3px;
}

.yr-automation-uodate .box-title{margin-bottom: 1.2rem;}
@media (min-width: 1024px){
  .yr-automation-uodate .width-35{
    flex: 0 0 35%; 
  }
  .yr-automation-uodate .width-30{
    /* flex: 0 0 23%; */
    margin-right: 0px;
  }  
  
  .yr-automation-uodate .width-35{
    margin-right: 1%;
}
}

@media (max-width: 1023px) {
.yr-automation-uodate .width-35{
      flex: 0 0 100%;
      max-width: 100%;  
    }
    .yr-automation-uodate .width-30{
      flex: 0 0 100%;
      max-width: 100%;
    }
}

@media (max-width: 1224px){
  .yr-automation-uodate-bt .right-btns{
      width: 100%;
      text-align: right;
      margin-top: -85px;
      margin-bottom: 0;
  }
  }
  
  .yr-automation-uodate-bt .right-btns {
       width: 100%;
      text-align: right;
  }