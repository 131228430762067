/* Start Popup Design */
.modal {
  overflow: auto;
}
/* .modal-body{padding: 0px;} */
.yr-col-3 {
  width: 30%;
  padding: 15px;
  position: relative;
}
.yr-col-3 .form-group label {
  font-size: 1rem;
}
.yr-col-3:nth-child(2) {
  width: 33%;
  border-left: 1px solid #ccc;
}
.yr-col-3:nth-child(3) {
  width: 37%;
  padding-bottom: 100px;
  border-left: 1px solid #ccc;
}
.green-btn {
  background: green;
  color: #fff;
  border-radius: 0;
}
.yr-line {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.yr-popBox-1 {
  padding: 2em 3em;
}
.yr-popBox-2 {
  padding: 2em 4em;
}
.p-t2em {
  padding-top: 2em;
}
.yr-col-3
  .kt-switch.kt-switch--success:not(.kt-switch--outline)
  input:checked
  ~ span:before,
.yr-col-3
  .kt-switch.kt-switch--success:not(.kt-switch--outline)
  input:empty
  ~ span:before {
  background-color: #008000;
}
.yr-col-3 .box-title {
  color: red;
}
.yr-buttonOk {
  position: absolute;
  bottom: 20px;
  top: auto;
  right: 20px;
}
.yr-buttonOk .yr-submitbtn {
  min-width: auto;
}
@media (max-width: 992px) {
  .yr-popBox-1 {
    padding: 2em 0em;
  }
  .yr-popBox-2 {
    padding: 2em 0em;
  }
}
@media (max-width: 768px) {
  .yr-col-3,
  .yr-col-3:nth-child(2),
  .yr-col-3:nth-child(3) {
    width: 100%;
    border-bottom: 1px solid #dedede;
    border-left: 0;
  }
  .yr-line {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
/* End Popup Design */
/* Start AutomaionPopup  */
.yr-autoPopup .box-title {
  padding: 2rem 3rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0;
}

.yr-autoPopup .rtBrd {
  border-right: 1px solid #ccc !important;
  padding-right: 0px;
}
.modelFooterBtn {
  text-align: left;
  padding-bottom: 20px;
  padding-left: 15px;
}

.yr-poupTab {
  border-bottom: 0px;
  max-height: 300px;
  margin-bottom: 50px;
  overflow: auto;
}
ul.yr-poupTab li {
  padding: 10px 15px;
  width: 100%;
}

ul.yr-poupTab li.active {
  background: #caffee;
  color: #333;
}

ul.yr-poupTab li a {
  color: #333;
}

.yr-autoPopup .pad0 {
  padding-right: 0px;
  padding-left: 0px;
}
.yr-autoPopup label.form-control {
  font-weight: 400;
  font-size: 13px;
  margin: 0px;
  height: auto;
}
.yr-popBox-1 .active {
  background: #caffee;
  color: #333;
}
/* End AutomaionPopup  */

/* additional css */
.yr-autoPopup .rtBrd.border-right-none {
  border-right: none !important;
}
