.yr-fbBox .pages-list {
  width: 100%;
}
.yr-fbBox .page-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid #e8e8e8;
  /* border-bottom: 1px solid #e8e8e8; */
}
.page-item-image {
  background-size: 40px 40px;
  background-position: 0 center;
  background-repeat: no-repeat;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  vertical-align: middle;
  display: inline-block;
  margin: 0px 16px 0 5px;
}
.yr-fbBox .page-item-container {
  flex: 5;
}

.yr-fbBox .action-block.yr-block {
  flex: 6;
}
.yr-fbBox .action-block {
  flex: 1;
}

.yr-fbBox button.btn.submit-button {
  margin-top: 0px;
  text-transform: uppercase;
  border-radius: 8px !important;
  margin-top: 5px;
  margin-left: 10px;
}

.yr-sml-h {
  font-size: 12px;
  color: #a0a0a0;
  margin-bottom: 20px;
}

.yr-fbBox .yr-mainTitle {
  padding-left: 0;
  font-size: 22px !important;
  font-weight: 300;
  color: #676767;
}
p.req-tos {
  margin: 15px 10px;
  text-align: center;
}
.yr-fbBox {
  max-width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 3px !important;
  display: block;
  padding: 10px 20px 0px;
  margin: 0 auto;
  /* margin-bottom: 10px; */
  /* margin-top: 10%; */
}
span.page-item-text.ng-binding {
  min-width: 200px;
  display: inline-flex;
  margin-top: 10px;
}

.fbloginpopup img {
  max-width: 359px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

#lastMessage {
  text-align: center;
  line-height: normal;
}
#fbscripttab h4.modal-title {
  font-weight: 600;
}
#fbscripttab .modal-header {
  background: #e8e8e8;
  padding: 10px 15px;
}
#fbscripttab .modal-body.fbscriptpage {
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  color: #6c6c6c;
}
.tabs-left-nav .active {
  color: #087ab5;
}
.tabs-left-nav > li {
  padding: 0;
}
.tabs-left-nav > li a {
  padding: 1.4rem 0;
  width: 100%;
  display: block;
}
.tabs-left-nav > li a.active {
  background-color: #f1f1f1;
  color: #087ab5;
}
.yr-manageUser-table .keep-integration tbody tr td div {
  width: 107px;
  word-break: break-all;
}
.yr-manageUser-table .salesforce-datatable tbody tr td div {
  width: 107px;
  word-break: break-all;
}

@media (max-width: 768px) {
  .yr-fbBox {
    min-width: 100%;
  }
  .yr-fbBox .page-item {
    display: inline-block;
  }
  .yr-fbBox .action-block {
    text-align: center;
    margin-top: 50px;
  }
  .page-item-container {
    margin-bottom: 10px;
  }
  .box-title.mx-lg-3 {
    text-align: center;
  }
}
