html,
body {
  height: 100%;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
* {
  font-family: "Poppins", sans-serif;
}
a {
  color: #087ab6;
}
.kt-dialog.kt-dialog--shown.kt-dialog--default.kt-dialog--loader.kt-dialog--top-center {
  background: url(media/logos/double-loader.gif);
  background-color: transparent;
  background-size: 50px;
  background-repeat: no-repeat;
  text-indent: -1000px;
  background-position: center;
  box-shadow: none;
  top: 40%;
  bottom: 40%;
}
.kt-container.kt-body.kt-grid.kt-grid--ver {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.kt-container.kt-body {
  margin-bottom: 100px;
}
.kt-header__top .kt-container {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.kt-header__bottom {
  background: #fff;
  border-bottom: 1px solid #ddd;
}
span.badge.badge-default {
  border-radius: 50%;
  border: 1px solid #ddd;
  color: #fff;
  width: 17px;
  height: 17px;
  line-height: 16px;
  margin-top: -22px;
  margin-left: -8px;
  padding: initial;
}
.yr-topbar-icon {
  line-height: 42px;
  color: #fff;
  font-size: 9px;
}
.clearfixd {
  clear: both;
  display: table;
}
.kt-aside-toggler span {
  background: url(media/icons/nav-menu-icon.svg) !important;
  width: 25px !important;
  height: 25px !important;
  top: 0 !important;
}
.yr-mt-20 {
  margin-top: 20px;
}
/* Added by rohit */
.kt-scrolltop {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
}
.kt-header__topbar
  .kt-header__topbar-item
  .kt-header__topbar-wrapper
  .kt-header__topbar-icon
  i {
  color: #fff;
}
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-username {
  color: #fff;
}
.kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon,
.kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon {
  background: transparent;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  box-shadow: 0px 4px 8px #b3b3b3;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  > .kt-menu__link-text {
  color: #fff !important;
}
span.kt-menu__link-text i {
  margin-right: 10px;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__link
  > .kt-menu__link-text {
  /* text-transform: capitalize !important;  */
  color: #087ab5;
}
.kt-page--fluid .yr-top-header {
  background-image: linear-gradient(to right, #087ab6, #00d39e);
  padding: 0 30px !important;
  margin: 0px !important;
  margin-top: 0px !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.kt-scrolltop--on .kt-header--fixed .yr-top-header,
.kt-header--minimize .kt-header--fixed .yr-top-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: 0px !important;
}
.kt-content.kt-grid__item.kt-grid__item--fluid {
  margin-bottom: 50px;
}
.kt-header--fixed.kt-header--minimize .kt-header {
  background: transparent !important;
}
.kt-subheader .kt-subheader__main .kt-subheader__title {
  font-weight: 400;
  color: #a6a8ab;
  font-size: 1em;
  padding-right: 5px;
}
.yr-pagetitle {
  padding: 5px 0 10px;
  color: #6d6e70;
  margin-bottom: 0px;
}
.yr-pagetitle h2 {
  font-size: 1.7rem;
}
/* End Upgrade Popup */
.yr-createTicket {
  padding: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 10px !important;
  margin-bottom: 30px;
  margin-top: 20px;
}
.yr-submitbtn {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  box-shadow: 0px 4px 8px #b3b3b3;
  border: 0 !important;
  min-width: 130px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  height: 44px;
  font-size: 16px;
  transform: 1s;
  border-radius: 5px;
}
.yr-submitbtn:hover {
  background-image: linear-gradient(45deg, #00d39e, #087ab6);
  color: #fff;
}
.yr-cancelbtn {
  background: linear-gradient(90deg, #00d39e, #087ab6);
  box-shadow: 0px 4px 8px #b3b3b3 !important;
  border-radius: 5px;
  color: #333;
  display: inline-block;
  font-size: 16px;
  padding: 2px;
  text-decoration: none;
  min-width: 130px;
  border: 0;
}
.yr-cancelbtn span {
  background: #fff;
  display: block;
  padding: 10px 20px;
  border-radius: 3px;
}
.mg-r {
  margin-right: 20px;
}
.dropzone .dz-message {
  margin: 3em 0;
  color: #ccc;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #fd2727;
  background-image: none;
}
.invalid-feedback {
  color: #fd2727;
}
.form-group label.form-control-label span {
  color: #fd2727;
}
img.img-responsive {
  max-width: 100%;
}
.yr-faicon::before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}
.kt-menu__item .yr-navicon-user:before {
  background-image: url(media/menu-icon/unselected/user-icon-svg.svg);
}
.kt-menu__item .yr-navicon-conversation:before {
  background-image: url(media/menu-icon/unselected/comment-icon-svg.svg);
}
.kt-menu__item .yr-navicon-review:before {
  background-image: url(media/menu-icon/unselected/star-icon-svg.svg);
}
.kt-menu__item .yr-navicon-chart:before {
  background-image: url(media/menu-icon/unselected/chart-icon-svg.svg);
}
.kt-menu__item .yr-navicon-setting:before {
  background-image: url(media/menu-icon/unselected/setting-icon-svg.svg);
}
.kt-menu__item .yr-navicon-light:before {
  background-image: url(media/menu-icon/unselected/light-icon-svg.svg);
}
.kt-menu__item .yr-navicon-t-script:before {
  background-image: url(media/menu-icon/unselected/t-script.svg);
}
.kt-menu__item .yr-navicon-gareicon:before {
  background-image: url(media/menu-icon/unselected/gaer-icon.png);
}
.kt-menu__item .yr-navicon-codeicon:before {
  background-image: url(media/menu-icon/unselected/code-icon.svg);
}
.kt-menu__item .yr-navicon-bot-builder:before {
  background-image: url(media/menu-icon/unselected/bot-builder.svg);
}

.kt-menu__item .yr-navicon-ticketList:before {
  background-image: url(media/menu-icon/unselected/ticket-list.svg);
}

.kt-menu__item--active .yr-navicon-ticketList:before {
  background-image: url(media/menu-icon/selected/ticket-list.svg);
}

.kt-menu__item--active .yr-navicon-codeicon:before {
  background-image: url(media/menu-icon/selected/code-icon.svg);
}
.kt-menu__item--active .yr-faicon.yr-navicon-gareicon:before {
  background-image: url(media/menu-icon/selected/gaer-icon.png);
}
.kt-menu__item--active .yr-navicon-t-script:before {
  background-image: url(media/menu-icon/selected/t-script.svg);
}
.kt-menu__item--active .yr-navicon-user:before {
  background-image: url(media/menu-icon/selected/user-icon-svg.svg);
}
.kt-menu__item--active .yr-navicon-conversation:before,
.yr-navicon-conversation:before {
  background-image: url(media/menu-icon/selected/comment-icon-svg.svg);
}
.kt-menu__item--active .yr-navicon-review:before {
  background-image: url(media/menu-icon/selected/star-icon-svg.svg);
}
.kt-menu__item--active .yr-navicon-chart:before {
  background-image: url(media/menu-icon/selected/chart-icon-svg.svg);
}
.kt-menu__item--active .yr-navicon-setting:before {
  background-image: url(media/menu-icon/selected/setting-icon-svg.svg);
}
.kt-menu__item--active .yr-navicon-light:before {
  background-image: url(media/menu-icon/selected/light-icon-svg.svg);
}
.kt-menu__item.kt-menu__item--active .yr-navicon-ticket:before {
  background-image: url(media/menu-icon/selected/support-ticket.svg);
  top: -2px;
}
.kt-menu__item .yr-navicon-ticket:before {
  background-image: url(media/menu-icon/unselected/support-ticket.svg);
  top: -2px;
}
.flaticon-dot-v:before {
  background-image: url(media/icons/support-ticket-dot-icon.svg);
  background-size: 5px;
  top: 5px;
}
.kt-body {
  position: relative;
}
.kt-subheader {
  padding-bottom: 0 !important;
}
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-wrapper
  img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.yr-desktoppagetitle span {
  margin-left: 5px;
}
.kt-header-menu-wrapper
  .kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__submenu
  > .kt-menu__subnav {
  padding: 0px;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  overflow: hidden;
  border: 1px solid #ddd;
  border-bottom: 0px;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__link {
  padding: 5px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__link:hover,
.kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item--active
  .kt-menu__link {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  color: #fff;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__link:hover
  .kt-menu__link-text,
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  color: #fff !important;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__link
  .kt-menu__hor-arrow {
  color: #087ab5;
  width: 16px;
  font-weight: 600;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-text {
  color: #087ab5;
  padding-left: 8px;
}
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
  > .kt-menu__link
  > .kt-menu__hor-arrow,
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
  > .kt-menu__link
  > .kt-menu__hor-arrow,
.kt-header-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  > .kt-menu__subnav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  > .kt-menu__hor-arrow {
  color: #fff !important;
  width: 16px;
  font-weight: 600;
}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link,
.nav-tabs.nav-tabs-line a.nav-link {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-width: 4px !important;
}
/* Profile page */
.kt-portlet--tabs .yr-createTicket {
  padding: 10px;
}
.kt-portlet--tabs .kt-portlet__head {
  border-bottom: 0px !important;
}
.modal button.close {
  z-index: 9999999;
  position: absolute;
  right: -6px;
  top: -6px;
  background-image: linear-gradient(45deg, #03bfa4, #0797a9);
  padding: 4px;
  border: 3px solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 85%;
  color: #fff;
  opacity: 1;
}
/* Css for manage team */
.yr-add-new {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  box-shadow: 0px 4px 8px #b3b3b3 !important;
  border: 0 !important;
  min-width: 130px;
  width:max-content;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  height: 48px;
  font-size: 16px;
  transform: 1s;
  font-size: 1rem;
  line-height: 2.3rem;
}
.yr-add-new:hover {
  color: #fff;
}
.mt--2rem {
  margin-top: -2rem;
}
.modal-content.white-bg {
  background-color: #fff;
}
.new-model-popup .modal-dialog {
  max-width: 700px;
}
.new-model-popup .modal-body {
  padding: 3.25rem;
  padding-top: 0;
}
.new-model-popup .form-group {
  margin-bottom: 0rem;
}
.new-model-popup .modal-header {
  border-bottom: 0;
}
.new-model-popup .modal-content .modal-header .modal-title {
  font-size: 1.8rem;
  padding: 1.25rem 0 0 1rem;
  color: #848588;
}
.team-member-list.table-bordered th,
.team-member-list.table-bordered td {
  border-top: 1px solid #ebedf2;
  border-bottom: 1px solid #ebedf2;
}
.blue {
  color: #1aadfb !important;
}
.red {
  color: red !important;
}
.new-model-popup .form-control {
  border: 0;
  border-bottom: 1px solid #aaacb3;
  border-radius: 0;
  padding: 0;
}
.new-model-popup .form-control:focus {
  border-bottom: 1px solid #777;
}
.new-model-popup .form-group label {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #848588;
}
.new-model-popup .kt-checkbox > span {
  border: 2px solid #c3c4c7;
}
.new-model-popup .yr-submitbtn {
  min-width: 120px;
  height: 42px;
  font-size: 13px;
}
.new-model-popup .yr-cancelbtn {
  font-size: 13px;
  min-width: 120px;
  height: 43px;
  box-shadow: 0px 4px 8px #b3b3b3 !important;
}
.dataTables_wrapper .pagination .page-item.active > .page-link,
.dataTables_wrapper .pagination .page-item:hover > .page-link {
  background: #087ab6 !important;
}
#kt_table_1_length,
#kt_table_1_filter {
  display: none;
}
.team-member-list a {
  color: #087ab6;
  font-weight: 500;
}
.team-member-list tbody td:nth-child(4) {
  white-space: nowrap;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* .team-member-list tbody td {
  white-space: nowrap;
} */
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  bottom: 18px;
  content: "";
  background-image: url(media/icons/svg/Navigation/Stockholm-icons/Navigation/Angle-up.svg);
  width: 14px;
  height: 14px;
  background-size: 14px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 1em;
  bottom: 10px;
  content: "";
  background-image: url(media/icons/svg/Navigation/Angle-down.svg);
  width: 14px;
  height: 14px;
  background-size: 14px;
}
.team-member-list.dataTable thead > tr > th.sorting_asc:first-child {
  padding-right: 9px;
}
.team-member-list thead th:first-child,
.table tbody td:first-child {
  width: 70px !important;
}
.team-member-list thead th:first-child:before,
.table thead th:first-child:after {
  display: none;
}
.kt-menu__item .yr-navicon-your-review:before {
  background-image: url(media/menu-icon/unselected/reviews.svg);
  background-size: 24px;
  width: 24px;
  margin-top: 3px;
}
.kt-menu__item--active .yr-navicon-your-review:before {
  background-image: url(media/menu-icon/selected/reviews.svg);
  background-size: 24px;
  width: 24px;
  margin-top: 3px;
}
.kt-menu__item .yr-navicon-team-activity:before {
  background-image: url(media/menu-icon/unselected/team-activity.svg);
}
.kt-menu__item--active .yr-navicon-team-activity:before {
  background-image: url(media/menu-icon/selected/team-activity.svg);
}
.kt-menu__item .yr-navicon-request-review:before {
  background-image: url(media/menu-icon/unselected/request-review.svg);
  margin-top: 3px;
}
/* YR */
.kt-checkbox > span:after {
  border: solid #00d09e;
}
.yr-upgrade-popup .kt-checkbox-inline .kt-checkbox:first-child {
  margin-right: 40px;
}
.modal .modal-content {
  border-radius: 10px !important;
}
/* End manage team */
div.dataTables_wrapper div.dataTables_filter {
  text-align: left !important;
}
.dataTables_scrollHeadInner {
  width: 100% !important;
}
.team-member-list thead th {
  white-space: nowrap;
}
.team-member-list thead th:first-child,
.table tbody td:first-child {
  min-width: 70px;
}
.team-member-list thead th {
  min-width: 120px;
}
.grey {
  color: #bfbfbf !important;
}
.new-model-popup .bootstrap-select .dropdown-toggle {
  border: 0px;
}
/* Manage User */
a.btn.btn-icon.gray i {
  color: #6c757d;
  cursor: pointer;
}
.btn.btn-icon i {
  font-size: 16px !important;
  cursor: pointer;
}
.btn.btn-icon {
  height: 2.5rem;
  width: 2rem;
}

.yr-manageUser-table span.yr-searchPlace-holder {
  position: absolute;
  right: 10px;
  top: 10px;
}
.scrollTBL {
  overflow: auto;
  max-height: 700px;
}
.yr-manageUser-table #generalSearch {
  padding-right: 60px;
}
.kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span
  > i {
  float: right;
  font-size: 18px !important;
  color: #aeb0b2 !important;
  margin-top: 5px;
  right: 0 !important;
}
.yr-usertable tbody td:first-child {
  white-space: nowrap;
}
.yr-usertable td:first-child > a {
  margin: 0 5px;
  font-size: 16px;
  width: 20px !important;
}
.yr-usertable td:first-child > a .la.la-trash {
  color: #aeb0b2 !important;
}
.sm-btn span {
  padding: 8px 20px;
}
.sm-btn {
  min-width: auto;
}
.user-datatable .kt-datatable__table .kt-datatable__head tr th span i {
  margin-left: 5px;
}
.kt-datatable__table .kt-datatable__head tr th span i.flaticon2-arrow-up:before,
.kt-datatable__table
  .kt-datatable__head
  tr
  th
  span
  i.flaticon2-arrow-down:before {
  content: "\f1ad";
  font-size: 12px !important;
}

.kt-datatable__table .kt-datatable__head tr th span i.flaticon2-arrow-up {
  transform: rotateX(180deg);
}
.kt-datatable__body {
  max-height: 500px;
}
.search-input-mu .ui.input > input {
  padding-right: 60px !important;
}
.yr-manageUser-table .kt-form--label-right .kt-input-icon {
  margin-left: 20px;
}

/*  */
/* Css for Datatable page */
.zy-primary-btn:after {
  content: "";
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-image: url(media/icons/circle-arrow.svg);
  display: inline-block;
  background-repeat: no-repeat;
  margin-left: 10px;
  position: relative;
  top: 5px;
}
.zy-primary-btn {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  box-shadow: 0px 4px 8px #b3b3b3 !important;
  border: 0 !important;
  min-width: 130px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  height: 48px;
  font-size: 16px;
  transform: 1s;
  font-size: 1rem;
  line-height: 2.3rem;
  white-space: nowrap;
  position: relative;
}
.zy-primary-btn:hover {
  color: #fff;
}
.header-search {
  width: 320px;
}
.btn-outline-info.zy-outline {
  background-color: #fff;
  color: #087ab5;
  border-color: #087ab5;
  margin-left: 20px;
}
.btn-outline-info.zy-outline:hover,
.btn-outline-info.zy-outline:not(:disabled):not(.disabled):active {
  background-color: #fff;
  color: #087ab5;
  border-color: #087ab5;
}
.kt-badge.kt-badge--web {
  color: #ffffff;
  background: #7f5bae;
}
.kt-badge.kt-badge--incomplete {
  color: #ffffff;
  background: #929497;
}
.kt-badge.kt-badge--sms {
  color: #ffffff;
  background: #079bae;
}
.bootstrap-select.arrow-color > .dropdown-toggle {
  border: 0;
  border-radius: 0 !important;
  border-bottom: 1px solid #bebfc1;
  letter-spacing: 1.1px;
}
.bootstrap-select.arrow-color.show > .dropdown-toggle.btn-light,
.bootstrap-select.arrow-color > .dropdown-toggle.btn-light:focus {
  border-color: #087ab5;
}
.bootstrap-select.arrow-color > .dropdown-toggle:after {
  font-size: 0.8rem;
  color: #087ab5;
}
.job-desc span {
  white-space: nowrap;
}
.des-link {
  color: #087ab5 !important;
  font-weight: 400;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--active,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--first:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--prev:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--next:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--last:hover {
  background: #087ab5;
  color: #fff;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sorted
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sorted
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span
  > i {
  color: #087ab5;
}

.yr-dataTableWidth {
  min-width: 100%;
  width: calc(1rem - 4px);
}
.tbl-header-opt {
  max-width: 250px;
  margin: 10px 3px;
  min-width: 150px;
}
.tbl-header-watch {
  width: 238px;
}
.tbl-header-opt-date {
  width: 250px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell {
  padding: 20px 10px;
}

.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle {
  background: #fff;
  color: #646c9a;
}
.zyra-datatable .bootstrap-select > .dropdown-toggle:after {
  font-size: 0.8rem;
  color: #087ab5;
  margin-right: -10px;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle:focus,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size.show
  > .btn.dropdown-toggle {
  color: #646c9a !important;
  background: #fff;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle {
  border: 0;
  border-radius: 0 !important;
  border-bottom: 1px solid #bebfc1 !important;
}
.kt-menu__item .yr-chat-completed:before {
  background-image: url(media/menu-icon/unselected/chat-completed-icon.svg);
}
.kt-menu__item .yr-reports:before {
  background-image: url(media/menu-icon/unselected/reports.svg);
}

.kt-menu__item--active .yr-chat-completed:before {
  background-image: url(media/menu-icon/selected/chat-completed-icon.svg);
}
.kt-menu__item--active .yr-reports:before {
  background-image: url(media/menu-icon/selected/reports.svg);
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span {
  background: #ffffff !important;
  border: 2px solid #c3c3c5 !important;
}

.zyra-datatable .kt-datatable__pager--top .kt-datatable__pager-nav {
  display: none !important;
}
.yr-d-float-right {
  float: right;
}
#premium_chat_lists thead tr {
  white-space: nowrap;
}
#premium_chat_lists tbody tr td:first-child {
  min-width: 50px;
}
#premium_chat_lists tbody tr td:nth-child(2) {
  white-space: nowrap;
}
#premium_chat_lists .dataTable thead .sorting:after {
  right: 0.5em;
  bottom: 12px;
}
#premium_chat_lists .dataTable thead .sorting:before {
  right: 0.5em;
  bottom: 25px;
}
/* End css for Datatable page */
/* Css for chat setup */
.zy-save-btn {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  border: 0 !important;
  height: 38px;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
}
.zy-cancel-btn {
  background-color: rgb(146, 146, 146) !important;
  border: 0 !important;
  height: 38px;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
}

.zy-save-btn:hover,
.zy-cancel-btn:hover {
  color: #fff;
}
.box-bg {
  background-color: #e9eef2;
}
.website-img {
  position: relative;
}
#logoIMG {
  display: block;
  width: 45px;
  height: 45px;
  position: absolute;
}
#logoIMG img {
  width: 100%;
}
.web-title,
.alignment-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #a5a5a5;
}
.web-title {
  margin: 16px 25px 60px 25px;
}
.align-label {
  font-weight: 500 !important;
  color: #6c7293;
}
.kt-radio.inalign > span {
  border: 2px solid #d1d7e2;
}
.kt-radio.inalign > input:checked ~ span {
  border: 1px solid #087ab5;
}
.kt-radio.inalign > span:after {
  border: solid #087ab5;
  background: #087ab5;
  margin-left: -6px;
  margin-top: -6px;
  width: 12px;
  height: 12px;
}

.noUi-tooltip {
  display: none;
}
.noUi-active .noUi-tooltip {
  display: block;
}
.noUi-target {
  background: #e6e7e8;
  margin-top: 10px;
  border-radius: 10px;
  padding-right: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.noUi-target.noUi-horizontal .noUi-handle {
  width: 12px;
  height: 25px;
  top: -6px;
  border-radius: 0%;
  background-color: #1e7bed;
  right: -14px !important;
}
.yr-chatleftbox {
  padding: 20px 10px 20px 40px !important;
  margin-top: 0px;
}
.yr-chatleftbox .kt-radio-inline {
  margin-top: 3px !important;
}
.yr-chatleftbox .kt-radio-inline .kt-radio {
  margin-bottom: 15px;
}
.item-content-end {
  align-items: flex-end !important;
}
/* End css for chat setup */
/* Chat setup 2 css */
.box-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #6c7293;
  margin-bottom: 2rem;
}
.yr-scrll-x {
  overflow-x: auto !important;
  overflow-y: hidden;
}
.column-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yr-chat-setup .column-label label {
  margin-bottom: 1.5rem;
}
.yr-chat-setup .zy-save-btn span i {
  font-size: 12px;
  padding: 0;
}
.zy-delete-btn {
  background-color: rgb(146, 146, 146) !important;
  border: 0 !important;
  height: 24px;
  color: #fff;
  border-radius: 2rem;
  padding: 0.25rem 1rem;
  font-size: 12px;
  cursor: pointer;
}
.zy-delete-btn:hover {
  color: #fff;
}
.kt-checkbox.chat-checkbox > span {
  border: 2px solid #d1d7e2;
}
.kt-checkbox.chat-checkbox {
  white-space: nowrap;
}
.kt-checkbox.chat-checkbox > span:after {
  border: solid #087ab5;
}
.chatbox-padd {
  padding: 2rem !important;
}
.required {
  color: #de1212 !important;
  font-size: 16px;
}
.form-control:focus {
  border-color: #087ab5;
}
.mat-style {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #cecece;
  background-color: transparent;
  height: 35px;
  padding: 0 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
ul.yr-time-ShowHide {
  display: flex;
  list-style: none;
  padding: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
ul.yr-time-ShowHide li {
  flex: 1;
  margin: 0 20px;
  min-width: 90px;
}
.yr-time-ShowHide span.custom-arrow {
  position: relative;
  width: 100%;
  display: inline-block;
}
.yr-time-ShowHide .form-group {
  margin-bottom: 0px;
}
.yr-time-ShowHide .custom-arrow:after {
  content: "\f1b1";
  font-family: Flaticon2;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
  width: 10%;
  height: 100%;
  pointer-events: none;
  font-size: 10px;
  color: #087ab5;
}
.yr-chat-setup .drop-label {
  margin-bottom: 0px;
  color: #087ab5;
  position: relative;
  bottom: -5px;
}
.yr-chat-setup .label-color {
  color: #087ab5;
}
.width-100 {
  width: 100px;
}
.yr-chat-setup label {
  color: #989898;
}
.yr-chat-setup .kt-radio-inline .kt-radio:last-child {
  margin-right: -20px;
}
.yr-chat-setup .column-label {
  min-width: 60px;
}
/* End chat setup 2 css */
.yr-manageUser-table .kt-form--label-right .kt-input-icon {
  margin-left: 24px;
  max-width: 300px;
}
/* Css for Request a Review */
.kt-menu__item--active .yr-navicon-request-review:before {
  background-image: url(media/menu-icon/selected/request-review.svg);
}
.title2 {
  color: #6d6e70;
}
.border-left-lg {
  border-left: 1px solid #ddd;
}
.request-a-review label {
  color: #999;
}
/* End css for Request a Review */
/* Css for Chat Customization */

.yr-preview {
  position: relative;
}
.yr-preview-menu {
  width: 266px;
  position: absolute;
  z-index: 1;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.yr-preview-menu a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  color: #818181;
  display: block;
  transition: 0.3s;
  font-family: "Poppins", sans-serif;
}

.yr-preview-menu a:hover {
  color: #087ab6;
}

.yr-preview-menu .closebtn {
  position: absolute;
  top: 25px;
  left: 30px;
  font-size: 30px;
  cursor: pointer;
  color: #818181;
}
.chat-footer-text {
  position: absolute;
  bottom: 10px;
  left: 30px;
  color: #828080 !important;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  width: 100%;
  display: inline-flex;
}
.chat-footer-text a {
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  padding: 0;
  color: #1e70bf;
  margin-left: 4px;
}

span.yr-chatlogo-mini {
  width: 45px;
  height: 45px;
  min-height: 45px;
  min-width: 45px;
  text-align: center;
  display: inline-block;
  border: 1px solid #99999996;
  border-radius: 50% !important;
  padding: 5px;
  margin-top: 25px;
  line-height: 29px;
}

.brand-left {
  height: 100%;
  padding: 10px;
  width: 100%;
  display: flex;
}
.small-img-logo {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  overflow: hidden;
  border: solid 1px #ececec47;
  display: inline-flex;
  margin-right: 5px;
  min-width: 50px;
  align-items: center;
  background: #fff;
  background: #fff;
  justify-content: center;
}
.yr-bgcolor-btn {
  display: block;
  outline: 0;
  border-radius: 50% !important;
  border: 0px solid #fff;
  padding: 0px;
  position: absolute;
  left: -45px;
  background-size: 26px;
  background-repeat: no-repeat;
  background-position: 5px;
  background-image: url(media/logos/zyra-tp-logo.png);
  position: static;
  margin: 0 auto;
  width: 49px;
  height: 48px;
  background-size: 39px;
}
.yr-bgcolor-btn-mini {
  display: block;
  outline: 0;
  border-radius: 50% !important;
  border: 0px solid #fff;
  padding: 0px;
  position: absolute;
  left: -45px;
  background-size: 26px;
  background-repeat: no-repeat;
  background-position: 4px;
  background-image: url(media/logos/zyra-tp-logo.png);
  position: static;
  margin: 0 auto;
  width: 35px;
  min-width: 35px;
  min-height: 35px;
  height: 35px;
  background-size: 28px;
  margin-top: 25px;
}
.yr-chat-box-row {
  display: flex;
  flex-flow: row wrap;
}
.yr-chat-box-row .box-title {
  font-weight: 600;
}
.yr-chat-round-box {
  padding: 20px 28px;
  border: 1px solid #dcdcdc;
  border-radius: 10px !important;
  margin-bottom: 30px;
}
.width-30 {
  margin-right: 28px;
}

.logo-img {
  width: 100%;
  margin: 10px 10px 10px 0;
  height: 90px;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: #e6e7e8;
}
.logo-img img {
  height: 100%;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.minicolors-theme-bootstrap .minicolors-swatch {
  top: 0px;
  left: 0px;
  width: 58%;
  height: 30px;
  border-radius: 0;
}
.form-control.demo {
  height: 30px;
  padding: 0.37rem 0rem;
  border-radius: 0;
  padding-left: 70%;
  background-color: #e6e7e8;
  display: block;
  border: 0px;
}
.form-control.demo:focus {
  border-color: #e6e7e8;
}
.add-gradient {
  margin-top: 5px;
  font-weight: 500;
  display: inline-block;
  color: #087ab6;
  cursor: pointer;
}

.chat-image-box {
  display: flex;
  flex-flow: row wrap;
}
.image-checkbox {
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 0;
  outline: 0;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px 0;
  position: relative;
}
.image-checkbox.active {
  background-color: #1e7bed;
}
.image-checkbox input[type="checkbox"] {
  /* display: none; */
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.image-checkbox img {
  width: 32px;
}

.image-checkbox-checked {
  border-color: #4783b0;
}
.kt-switch.chat-cus-check input:checked ~ span:before {
  content: "ON";
  display: inline-block;
  color: #fff;
  font-weight: 600;
  padding-left: 6px;
}
.kt-switch.chat-cus-check input ~ span:before {
  content: "OFF";
  display: inline-block;
  color: #fff;
  font-weight: 600;
  padding-left: 31px;
}
.kt-switch.chat-cus-check input:empty ~ span:before {
  width: 65px;
}
.kt-switch.chat-cus-check input:checked ~ span:after {
  margin-left: 37px;
}
.yr-selectArrow {
  position: relative;
}
.yr-selectArrow select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.yr-selectArrow:after {
  border: 0;
  font-size: 0.8rem;
  color: #087ab5;
  display: inline-block;
  font-family: Flaticon2;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  content: "";
  vertical-align: middle;
  position: absolute;
  margin-left: 0.5rem /*rtl:ignore*/;
  opacity: 0.7;
  right: 7px;
  top: 14px;
}
.bold {
  font-weight: 600 !important;
}
.yr-preview-box {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.yr-preview {
  max-width: 360px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 16px !important;
  margin-bottom: 18px;
  overflow: hidden;
  margin-top: 35px;
}
.yr-preview header {
  /* background-image: linear-gradient(45deg, #087ab6, #00d39e) !important; */
  display: flex;
  align-items: center;
  height: 110px;
  justify-content: space-between;
  padding: 20px 23px;
  position: relative;
}
.yr-preview header svg {
  position: absolute;
  left: 0;
  bottom: 0;
  bottom: -13px;
}
.yr-preview header path {
  fill: linear-gradient(45deg, #087ab6, #00d39e);
}
.brand-name {
  margin-left: 10px;
  color: #fff;
  font-size: 22px;
  font-weight: 500;

  padding-left: 5px;
  max-width: 200px;
  align-items: center;
  display: inline-flex;
}
.yr-nav,
.yr-close {
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  width: 32px;
}
.yr-logo img {
  max-width: 55px;
}
.yr-body {
  padding: 20px 30px;
}
.yr-body-top {
  display: flex;
  justify-content: flex-start;
  padding: 2px 3px;
  margin-bottom: 245px;
}
.yr-body-top img {
  max-width: 30px;
  max-height: 35px;
}
.welcome-text {
  margin-left: 10px;
  background-color: #e9e9e9;
  padding: 10px 15px;
  border-radius: 10px 10px 10px 0px;
  color: #000;
  font-weight: 400;
  font-size: 13px;
}
.yr-arm {
  width: 16px;
  height: 14px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-arm:after {
  content: url(media/logos/arm.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-chat {
  width: 11px;
  height: 11px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-chat:after {
  content: url(media/logos/chaticon.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-call {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-call:after {
  content: url(media/logos/smartphone.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-star {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-star:after {
  content: url(media/logos/star-review.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.footer-logo {
  width: 16px;
  height: 14px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.footer-logo:after {
  content: url(media/logos/footer-logo.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yrbtn {
  /* background-image: linear-gradient(45deg, #087ab6, #00d39e) !important; */
  border: 0 !important;
  height: 38px;
  width: 100%;
  color: #fff;
  border-radius: 2rem;
  white-space: nowrap;
  margin-bottom: 10px;
}
.yrbtn:hover {
  color: #fff;
}
.yr-footer {
  margin-top: 15px;
}
.yr-footer p {
  font-weight: 500;
}
.yr-chat-logo-row {
  max-width: 360px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.yr-chat-logo {
  width: 52px;
  height: 52px;
  outline: 0;
  z-index: 99999;
  border-radius: 50% !important;
  border: 0px solid #fff;
  /* background: linear-gradient(45deg, #286d94 28%, #09cc90 66%); */
  padding: 0px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}
.yr-chat-logo:before {
  content: "";
  background: url(media/logos/img-icon1-white.png) no-repeat;
  display: flex;
  background-size: 32px;
  height: 32px;
  width: 32px;
  background-position: center;
  margin: auto;
}
.yr-max-height {
  max-height: 865px;
  overflow: auto;
}
.yr-button {
  position: relative;
  z-index: 1;
}
.yr-manageUser-table span.yr-searchPlace-holder {
  position: absolute;
  right: 10px;
  top: 10px;
}
@media (min-width: 1200px) {
  .width-30 {
    flex: 0 0 28%;
    max-width: 28%;
  }
  .width-40 {
    flex: 0 0 38%;
    max-width: 38%;
  }
}
@media (min-width: 1400px) {
  .width-30 {
    flex: 0 0 28.5%;
    max-width: 28.5%;
  }
  .width-40 {
    flex: 0 0 38.8%;
    max-width: 38.8%;
  }
  .yr-chat-box-row {
    flex-flow: row nowrap;
  }
}
/* End css for Chat Customization */

/* Start Workspace Automation */
.yr-workspace > ul {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.yr-workspace > ul > li:first-child {
  align-items: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  background-color: #087ab6;
}
.yr-workspace > ul > li:first-child > div {
  align-items: center;
}
.yr-workspace h2 {
  color: #fff;
  font-size: 18px;
  line-height: 25px;
}
.yr-workspaceBoxInner {
  position: relative;
  color: #fff;
}
.yr-workspaceBoxInner.yr-del-botbuilder {
  position: absolute;
  color: #fff;
  right: 12px;
  top: 10px;
  z-index: 99;
  text-align: right;
  font-size: 18px;
}
ul.yr-dotMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: none;
  right: -10px;
  top: 10px;
  text-align: left;
}
ul.yr-dotMenu li {
  background: #d8d8da;
  padding: 0 5px;
  border-bottom: 1px solid #b2b2b5;
}
ul.yr-dotMenu li span {
  display: inline-block;
  font-size: 12px;
  color: #4b5d71;
  line-height: 28px !important;
  font-weight: 600;
  min-width: 69px;
  text-align: left;
  cursor: pointer;
}
ul.yr-dotMenu li span i {
  width: 22px;
  display: inline-block;
}
.yr-workspaceBoxInner .yr-del-botbuilder:hover ul.yr-dotMenu {
  display: block;
}
.yr-workspaceBoxInner .yr-del-botbuilder {
  position: absolute;
  color: #fff;
  right: -12px;
  top: -10px;
  z-index: 99;
  text-align: right;
  font-size: 18px;
}
/* .yr-workspaceBoxInner .yr-branchDetails ul {
  list-style: none;
  margin: 0;
  font-size: 12px;
  width: 100%;
  display: flex;
  padding: 10px 0;
  padding-top: 0px;
} */
ul.yr-dotMenu:after {
  position: absolute;
  top: -6px;
  right: 7px;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: "";
  border-bottom-color: #d8d8da;
}
.yr-workspaceBoxInner .yr-branchDetails ul li {
  flex: 1 1;
  font-size: 10px;
  font-weight: 600;
  max-width: 88px;
}
.yr-workspaceBoxInner .yr-branchDetails ul li span {
  display: block;
  font-weight: 400;
}
.yr-branchDetails .title {
  font-weight: 400;
}
/* End Workspace Automation */

/* Css for Installation and Integration */
.tabs-left-nav,
.tabs-box {
  border-bottom: none;
}
.tabs-left-nav > li {
  float: none;
  margin: 0px;
  width: 100%;
  padding: 1.4rem 0;
  border-bottom: 1px solid #dcdcdc;
}
.tabs-left-nav > li > a,
.tabs-box > li > a {
  color: #828080;
  font-weight: 500;
}
.tabs-box li {
  width: 100%;
  border-bottom: none;
}
.tabs-box > li > a {
  text-align: center;
}
.div-tab {
  width: 100%;
  color: #555;
  padding-top: 50px;
  margin-bottom: 20px;
}
.tab-content p {
  color: #828080;
}
.div-tab p.text-blue {
  color: #087ab5;
  padding-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.div-tab.active {
  background: #eae8e8;
}
.yr-script {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 40px;
  max-height: 85px;
  overflow: auto;
}
.icon-mr {
  margin-right: 15px;
}
.yr-icon-install {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-install:after {
  content: url(media/logos/sl-icon-1.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-icon-facebook {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-facebook:after {
  content: url(media/logos/fb-icon.png);
  position: absolute;
  left: 3px;
  top: 0px;
}
/* @rohit */

.yr-icon-google {
  width: 12px !important;
  height: 12px !important;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-google:after {
  content: url(media/logos/google3.png);
  position: absolute;
  left: 3px;
  top: 0px;
  width: 12px !important;
  height: 12px !important;
}

/* @rohit */
.yr-icon-workflows {
  width: 12px !important;
  height: 12px !important;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-workflows:after {
  content: url(media/logos/workflows.svg);
  position: absolute;
  left: 3px;
  top: 0px;
  width: 12px !important;
  height: 12px !important;
}

.yr-icon-sms-texting {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-sms-texting:after {
  content: url(media/logos/email-small.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-icon-sales-force {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-sales-force:after {
  content: url(media/logos/cloud-small.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-icon-keap {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-keap:after {
  content: url(media/logos/sl-icon-4-small.png);
  position: absolute;
  left: 4px;
  top: 0px;
}
.yr-icon-marketsharp {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-marketsharp:after {
  content: url(media/logos/marketsharp.png);
  position: absolute;
  left: 4px;
  top: 0px;
}
.yr-icon-zapier {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-zapier:after {
  content: url(media/logos/sl-icon-5-small.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-icon-webhook {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-webhook:after {
  content: url(media/logos/sl-icon-6-small.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-icon-tag-m-ga {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.yr-icon-tag-m-ga:after {
  content: url(media/logos/sl-icon-7small.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-tutorialBtn a:after {
  content: url(media/logos/playbatn.png);
  position: absolute;
  right: 0;
  top: 0px;
}
.yr-installChat {
  margin-top: 70px;
}
.yr-tutorialBtn a {
  font-weight: 500;
  color: #828080;
  padding-right: 50px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
}
.p-title {
  font-weight: 500;
  color: #8a8a8a;
  margin-bottom: 30px;
}
.yr-installChat h3 {
  font-size: 18px;
  color: #676565;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
}
/* End css for Installation and Integration */
/* Css for LiveChat */
.livechat-left {
  padding-top: 0.3rem;
  border-right: 1px solid #ddd;
}
.livechat-left
  .accordion.accordion-toggle-arrow
  .card
  .card-header
  .card-title {
  padding-left: 30px;
  color: #868688;
}
.livechat-left .accordion.accordion-light .card .card-header .card-title:after {
  left: 0;
  color: #087ab5;
  font-weight: 900;
  font-size: 14px;
}
.livechat-left
  .accordion.accordion-toggle-arrow
  .card
  .card-header
  .card-title.collapsed {
  color: #868688;
}
.livechat-left .accordion.accordion-light .card {
  border-top: 1px solid #ddd;
  padding: 0 30px;
}
.livechat-left .accordion.accordion-light .card:first-child {
  border-top: 0;
}
.bigwithshadow {
  box-shadow: 0px 4px 8px #b3b3b3;
  height: 58px;
  padding: 0 22px;
}
.livechat-left
  .kt-notification-v2
  .kt-notification-v2__item
  .kt-notification-v2__itek-wrapper
  .kt-notification-v2__item-title {
  font-weight: 400;
}
.livechat-left .kt-notification-v2 .kt-notification-v2__item:hover {
  background-color: #fff;
}
.livechat-left
  .kt-notification-v2
  .kt-notification-v2__item
  .kt-notification-v2__itek-wrapper
  .kt-notification-v2__item-desc,
.livechat-left
  .kt-notification-v2
  .kt-notification-v2__item
  .kt-notification-v2__item-time {
  font-size: 1.1rem;
  font-weight: 400;
  color: #5d5b6f;
}
.livechat-left
  .kt-notification-v2
  .kt-notification-v2__item
  .kt-notification-v2__item-time {
  opacity: 0.7;
}
.livechat-left
  .kt-notification-v2
  .kt-notification-v2__item
  .kt-notification-v2__item-icon {
  background-color: #dedede;
}
.time-waiting {
  color: #5d5b6f;
  opacity: 0.8;
}
.kt-portlet__foot.right-footer {
  border: 1px solid #ddd;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  height: 80px;
  margin: 15px;
  margin-top: 0px;
}
.kt-widget3__item.right-item {
  max-width: 480px;
  float: right;
}
.kt-widget3__item.right-item .kt-widget3__header,
.kt-widget3__item.left-item .kt-widget3__header {
  justify-content: flex-start;
}
.chat-img {
  width: 30px;
  height: 30px;
  background-color: #eee;
  border-radius: 50%;
  display: inline-block;
}
.chat-img img {
  width: 100% !important;
  border-radius: 50%;
}
.kt-widget3__item.left-item {
  max-width: 580px;
  float: left;
}
.highlited-body {
  display: flex;
  align-items: center;
}
.highlited-body p {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  padding: 14px 18px;
  border-radius: 0px 15px 15px;
}
.highlited-body .kt-widget3__text {
  color: #fff !important;
}
.highlited-body .btn {
  color: #b1b1b1;
}
.right-side-chat .kt-portlet__head {
  min-height: 80px;
}
.mark-as-closed {
  color: #087ab5;
  font-size: 12px;
}
.assignedto {
  color: #a7abc3;
  font-size: 12px;
}
.chat-me {
  color: #000;
}
.reply-box {
  width: 100%;
  margin-right: 90px;
  margin-bottom: 0;
}
.reply-box .form-control {
  border: none;
}
.cyat-copy-clipboard {
  width: 17px;
  height: 17px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.cyat-copy-clipboard:after {
  content: url(media/logos/copy-clip.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.chat-bookmark {
  width: 17px;
  height: 17px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.chat-bookmark:after {
  content: url(media/logos/bookmark.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.chat-emoji {
  width: 17px;
  height: 17px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.chat-emoji:after {
  content: url(media/logos/emoji.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.chatty-icon .btn {
  padding: 0.65rem 0.3rem;
}
.kt-portlet .kt-portlet__head.chat-header {
  flex-flow: row wrap;
  border-bottom: 1px solid #ddd;
}
/* End css for LiveChat */

@media (min-width: 1024px) {
  .yr-pd0-lr {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .mg-l-0 {
    margin-left: 0px !important;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu {
    max-width: 225px;
  }
  .kt-header__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: auto;
  }
  .kt-header-menu .kt-menu__nav {
    width: 100%;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item {
    flex: 1;
    justify-content: center;
  }
  .yr-manageUser-table #generalSearch {
    margin-left: 7px;
  }
  .kt-header--fixed .kt-header {
    background: transparent;
  }
  .yr-mobpagetitle {
    display: none;
  }
  .kt-aside-toggler {
    margin-right: 15px;
  }
  .yr-desktoppagetitle {
    color: #fff;
    font-size: 16px;
    line-height: 60px;
  }
  .kt-header-menu-mobile {
    width: 100%;
  }
  .kt-header-menu-mobile ul {
    margin: auto !important;
  }
  /* .kt-header__bottom .kt-header-menu-wrapper {
  border-bottom: 1px solid #e6e6e6;
} */
  .yr-body-p {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    color: #fff !important;
  }
  .nav-tabs.nav-tabs-line .nav-link {
    font-size: 1.2rem;
  }
  .modal-open {
    padding-top: 80px !important;
  }
  /* Datatable */
  .tbl-header-opt {
    flex: 1;
    margin: 10px 3px;
  }
  .tbl-header-opt:first-child {
    margin-left: 10px;
  }
  /* datatable */
  .min-height {
    min-height: 650px;
    height: 94%;
  }
}
@media (max-width: 1024px) {
  .kt-header-menu-mobile
    .kt-menu__nav
    .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    line-height: 44px;
    color: #fff;
    display: block;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    display: block;
    line-height: 22px;
  }
  .kt-container {
    margin: 0px !important;
  }
  /* .kt-body:after{
    height: 0px;
  } */
  .yr-mobpagetitle {
    display: block;
    margin-top: 40px;
    padding-left: 20px;
    color: #034a6f;
    font-weight: 600;
    font-size: 14px;
  }
  ul.yr-chartsDot {
    width: auto;
  }
  .yr-upgrade-popup .modal-dialog {
    max-width: 90%;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-toggler
    span,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-toggler
    span::before,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-toggler
    span::after {
    background-color: #fff;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-topbar-toggler.kt-header-mobile__toolbar-topbar-toggler--active
    i,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toolbar-topbar-toggler:hover
    i {
    color: #ffffff !important;
  }
  .kt-header-mobile__toolbar-toggler--left span,
  .kt-aside-toggler.kt-aside-toggler--left span {
    background: url(media/icons/nav-menu-icon.svg) !important;
    width: 25px !important;
    height: 25px !important;
    top: 0 !important;
  }
  .kt-header-mobile {
    background-image: linear-gradient(to right, #087ab6, #00d39e);
    z-index: 100 !important;
  }
  .kt-header__topbar {
    margin-top: -70px;
    background-color: #087bb6;
    border-top: 0px;
  }
  .kt-page--fluid .yr-top-header {
    margin-top: 0px !important;
  }
  .zyra-datatable .kt-datatable__detail .job-desc span {
    white-space: inherit;
    word-break: break-all;
  }
  /* Css for manage team */
  .new-model-popup .modal-dialog {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    top: 30px;
  }
  /* End css for manage team */
  /* Css for chat setup */
  .kt-portlet .kt-portlet__head {
    flex-direction: column;
  }
  /* End css for chat setup */
  /* Css for Chat Customization */
  .width-30 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .width-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  /* End css for Chat Customization */
  .yr-installChat {
    margin-top: 20px;
  }
}
@media (min-width: 1140px) {
  .kt-header-menu .kt-menu__nav {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1139px) {
  .kt-header-menu .kt-menu__nav {
    padding-left: 10px;
    padding-right: 10px;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    padding: 0 15px;
  }
  .ui.segment {
    max-width: 100% !important;
  }
}
@media (max-width: 768px) {
  /* datatable */
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__body
    .kt-datatable__row-detail
    .kt-datatable__detail {
    padding: 10px !important;
  }
  .zyra-datatable td.kt-datatable__cell {
    zoom: 90%;
  }
  /*  */
  .yr-sideSection h3 {
    min-height: auto;
  }
  .yr-sideSection {
    text-align: center;
  }
  .yr-multibtn.right-box {
    max-width: 250px;
    margin: 0 auto;
  }
  .yr-submitbtn,
  .yr-cancelbtn {
    min-width: 100px;
  }
  .yr-pagetitle.yr-body-p.d-flex.justify-content-between {
    flex-direction: column;
  }
  .yr-pagetitle.yr-body-p.d-flex.justify-content-between div {
    margin-top: 0px !important;
  }
  .yr-d-float-right {
    float: left;
  }
  .yr-chatleftbox {
    padding: 20px 10px 20px 20px !important;
  }
  .yr-chat-setup .column-label {
    min-width: auto;
  }
  .yr-manageUser-table .kt-form--label-right .kt-input-icon {
    margin-left: 0px;
  }
  .kt-portlet__head-toolbar.d-flex.justify-content-end.item-content-end {
    margin-top: 10px;
  }
  .kt-portlet {
    box-shadow: none !important;
  }
  .yr-chatleftbox .kt-radio-inline {
    margin-top: 15px !important;
  }
  #logoIMG {
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
  }
  .website-img {
    margin: 0px !important;
  }
  .kt-portlet .kt-portlet__body,
  .yr-pd0-mob {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .web-title {
    margin-bottom: 30px;
  }
}
.mh-selectOPt {
  width: 50% !important;
  margin-left: 11px;
  padding-left: 20px !important;
}
/* Css for Chat Customization */
@media (max-width: 480px) {
  .yr-logo img {
    max-width: 40px;
  }
  .brand-name {
    font-size: 16px;
  }
  .yr-preview-box {
    width: 120%;
    margin: 0 -10%;
  }
  .nav-tabs.nav-tabs-line .nav-item {
    min-width: 140px;
  }
  .mh-selectOPt {
    width: 100% !important;
    margin-left: 11px;
    margin-bottom: 15px;
    padding-left: 20px;
  }
}
.mh-profilePage {
  padding: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 10px !important;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 30px !important;
}
.ui.form .fields.error .field textarea,
.ui.form .fields.error .field select,
.ui.form .fields.error .field input:not([type]),
.ui.form .fields.error .field input[type="date"],
.ui.form .fields.error .field input[type="datetime-local"],
.ui.form .fields.error .field input[type="email"],
.ui.form .fields.error .field input[type="number"],
.ui.form .fields.error .field input[type="password"],
.ui.form .fields.error .field input[type="search"],
.ui.form .fields.error .field input[type="tel"],
.ui.form .fields.error .field input[type="time"],
.ui.form .fields.error .field input[type="text"],
.ui.form .fields.error .field input[type="file"],
.ui.form .fields.error .field input[type="url"],
.ui.form .field.error textarea,
.ui.form .field.error select,
.ui.form .field.error input:not([type]),
.ui.form .field.error input[type="date"],
.ui.form .field.error input[type="datetime-local"],
.ui.form .field.error input[type="email"],
.ui.form .field.error input[type="number"],
.ui.form .field.error input[type="password"],
.ui.form .field.error input[type="search"],
.ui.form .field.error input[type="tel"],
.ui.form .field.error input[type="time"],
.ui.form .field.error input[type="text"],
.ui.form .field.error input[type="file"],
.ui.form .field.error input[type="url"] {
  background: #fff !important;
  border-color: #e73d4a !important;
  color: #555 !important;
  border-radius: "";
  box-shadow: none;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 12px !important;
  height: 25px !important;

  background-color: #1e7bed !important;
  border-radius: 0 !important;
  outline: none;
  border: 1px solid #f7f8fa !important;
  -webkit-box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.7);
  box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.7);
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: "";
  position: absolute;
  width: 0px !important;
  height: 0px !important;
  top: 6px;
  left: 6px;
  border-radius: 0 !important;

  box-shadow: none !important;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #e6e7e8 !important;
  border-radius: 10px;
  top: 0;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: none !important;
}

.ui.green.message {
  background-color: #abe7ed !important;
  border-color: #abe7ed !important;
  color: #27a4b0 !important;
  box-shadow: 0 0 0 1px #eee inset, 0 0 0 0 transparent;
}

.ui.red.message {
  background-color: #fbe1e3 !important;
  border-color: #fbe1e3 !important;
  color: #e73d4a !important;
  box-shadow: 0 0 0 1px #eee inset, 0 0 0 0 transparent;
}

.yr-cancelbtn span {
  background: #fff;
  display: block;
  padding: 8px 20px;
  border-radius: 3px;
}

/* .ui.loading.form:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -1.5em 0 0 -1.5em;
  width: 4em !important;
  height: 4em !important;
   animation: none !important;
  animation-iteration-count: none !important;
  border-radius: none !important;
  border-color: #767676 rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.1);
  border-style: none !important;
  border-width: 0em !important;
  background-image: url(/static/media/double-loader.a7e52b21.gif) !important;
  background-position: 0px;
  background-size: 3.7em !important;
  visibility: visible;
  z-index: 101;
  
}

.ui.loading.form:before{
  content: "" !important;
}

.ui.loading.form{
  z-index: 99;
  background: none !important;
} */

.mh-custom-pad0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.mh-custom-marg0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.kt-header {
  z-index: 99 !important;
}

.kt-header {
  z-index: 99 !important;
}

.hourrules input[type="radio"] {
  position: relative;
}
.yr-chat-setup .label-color {
  color: #087ab5;
}

.yr-chat-setup .form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}

.cursorpointer {
  cursor: pointer;
}

.customValid {
  position: relative;
  top: -11px;
  left: 1px;
}
.mh-oneChatContainer {
  height: 425px !important;
  overflow-x: auto;
}

.mh-leftBarChatMessage {
  width: 187px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mh-chatLeftAUBox {
  overflow: hidden !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 40px !important;
  min-width: 40px !important;
}
.mh-visitorDetailOnLeftBar {
  padding-left: 8px !important;
}

.hippaBox {
  width: 100%;
  position: absolute;
  height: 709px;
  background-color: white;
  z-index: 11;
  top: 0px;
}

.completedchats {
  overflow: auto;
}
.completedchatmain select.arrow-color:after {
  content: "\f1b1";
  font-family: Flaticon2;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
  width: 10%;
  height: 100%;
  pointer-events: none;
  font-size: 10px;
  color: #087ab5;
}

.completedchats .kt-form__control {
  display: inline-flex;
  white-space: nowrap;
  line-height: 35px;
}

.completedchats select.form-control.bootstrap-select.arrow-color.mat-style {
  padding: 6px 10px;
  margin-left: 8px;
}

.completedchats .media-body .media-heading {
  margin-bottom: 0px;
}

.completedchats .media-body {
  padding-top: 6px;
}

.c-comment-list .media {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.c-comment-list .media .media-left {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  overflow: hidden;
}

.completedchats form#notesForm {
  padding-top: 20px;
  line-height: 30px;
}

.completedchats h4.media-heading {
  font-size: 14px;
}

#notesForm .yr-submitbtn {
  width: 100% !important;
  margin-top: 20px;
}
.completedchatmain .bootstrap-select.mat-style {
  height: 40px !important;
  width: 160px;
}
.completedchatmain .kt-checkbox > span {
  border: 2px solid #c3c3c5 !important;
}
.chatstatus .custom-arrow:after {
  top: 4px;
}
#additional_captured.ui.table {
  border: 1px solid #dddddd !important;
}
#additional_captured.ui.table tr td {
  border: 1px solid #dddddd !important;
}

.mh-customMarginPinRow {
  margin-top: 20px;
  padding-left: 20px !important;
}
.mh-pinInput {
  text-align: center;
}

.mh-h5-custom {
  font-size: 18px !important;
  padding-left: 1px !important;
}

.mh-pinInput-redBorder {
  border: solid 1px red !important;
}
.mh-pinError-cls {
  padding-left: 18px !important;
}

.mh-chatLeftBarHover:hover {
  background-color: #f5f5f5 !important;
  cursor: pointer;
}

.yr-right-chatbox {
  min-height: 760px;
}
.yr-right-chatbox {
  background: #f5f1ec;
  width: 100%;
  min-height: 540px;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  position: relative;
  padding-bottom: 55px;
  margin-bottom: 15px;
}
.yr-tab-left,
.yr-right-chatbox {
  margin-top: 15px;
}
.yr-rightbar-chatBox {
  background-color: #fff !important;
  padding: 3%;
}
.sensitive-chat-info-box {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 18px;
  width: 96%;
  height: 98%;
  background: white;
  border-radius: 5px !important;
  z-index: 9;
}
.yr-openMsg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  /* background-image: linear-gradient(to right, #56abd8, #b6ffed) !important; */
  border: 1px solid #ccc;
  margin-top: 18px;
}

.yr-awatarImg {
  margin-bottom: 10px;
  margin-top: 25px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  display: flex;
}
.yr-welcome-box span img {
  max-width: 150px;
  max-height: 100px;
}
.mh-blankBoxOnLiveChat {
  padding-left: 16px;
  padding-right: 26px;
}

.mh-userType-premium {
  display: none;
}

.mh-noChatAvailableOnLeftBar {
  background-color: #f5f5f5;
  cursor: default !important;
  width: 100%;
  height: 39px;
  padding-top: 10px;
  padding-left: 20px;
}

.mh-automatedChat {
  height: 265px;
  overflow-y: auto;
  padding: 0 0px !important;
  padding-left: 4px !important;
}

.mh-timeText {
  padding-right: 18px;
}

.emoji-picker-react {
  bottom: 36px;
  right: 7px;
  position: absolute !important;
}
.emoji-search {
  display: none;
}

.skin-tones-list {
  display: none;
}

.mh-joinIntotheConversation {
  position: absolute;
  height: 50px;
  width: 88%;
  bottom: 30px;
  text-align: center;
  padding-top: 14px;
  background-image: linear-gradient(to right, #087ab6, #00d39e);
  border-radius: 5px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  z-index: 9;
  cursor: pointer;
}

.mh-commentTextArea {
  padding-top: 27px;
}

.mh-chatLeftBarActive {
  background-color: #e2e2e2;
  cursor: pointer;
}

.yr-workspace > ul > li.active {
  background: #00bf9e;
}
.yr-wksSelected {
  color: green;
}
.upper_comp_div {
  background-color: #fff !important;
  border: none !important;
}

.requestReviewForm .form-group label {
  margin-top: 0px !important;
}
.mh-defaultChatLogo {
  height: 39px;
  width: 41px !important;
  margin-left: 1px;
  margin-top: 1px;
}

.mh-companyChatLogo {
  height: 42px;
}

.mh-TypingLoaderImg {
  height: 14px;
  margin-left: 26px;
  margin-top: 7px;
}

.mh-loading-custom {
  z-index: 99999 !important;
}

.mh-noChatBar {
  text-align: center;
  border: none;
}
.kt-badge--error {
  background-color: red;
  color: #fff;
}
.yr-popBox-1 .active {
  background: #caffee;
  color: #333;
}
.cursordiv {
  cursor: pointer;
}

.yr-workspace > ul > li {
  width: 22%;
  background: #5e738b;
  margin: 10px 1.5%;
  padding: 10px;
  min-width: 250px;
  height: 120px;
  border-radius: 10px;
}
.yr-workspaceBoxInner {
  padding-left: 8px;
}
.yr-workspaceBoxInner .yr-del-botbuilder .btn.btn-icon i {
  color: #fff;
}

.yr-workspaceBoxInner .yr-del-botbuilder button:hover .dropdown-menu {
  display: block;
}
.yr-branchDetails .title {
  font-weight: 400;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.yr-workspaceBoxInner .yr-branchDetails ul {
  list-style: none;
  margin: 0;
  font-size: 12px;
  width: 100%;
  display: flex;
  padding: 10px 0;
  padding-top: 25px;
}
li.redBox .yr-workspaceBoxInner:after {
  content: url(media/icons/workspace-down-icon.svg) !important;
  position: absolute;
  right: 0;
  bottom: 0px;
}
.yr-workspaceBoxInner:after {
  content: url(media/icons/workspace-up-icon.svg);
  position: absolute;
  right: 0;
  bottom: 0px;
}
.yr-del-botbuilder .dropdown-menu {
  min-width: 8rem;
  padding: 0px;
}
.duplicatepopup label {
  cursor: pointer;
}

.yr-manageUser-table .mat-style {
  padding: 0 30px 0 5px;
}
.yr-manageUser-table .yr-time-ShowHide .custom-arrow:after {
  right: 5px;
}
.yr-manageUser-table .tbl-header-opt {
  min-width: 120px;
}
.yr-manageUser-table .showrangepicker .mat-style {
  line-height: 35px;
}

.mh-Unread .kt-notification-v2__item-title {
  font-weight: 600 !important;
}

.mh-Unread .mh-leftBarChatMessage {
  font-weight: 600 !important;
}

.mh-Unread .kt-notification-v2__item-time {
  font-weight: 600 !important;
}

.mh-connDisconnLeaveMsg {
  margin-bottom: 25px;
  text-align: center;
  border-bottom: solid 1px;
}

.mh-connDisconnLeaveMsg-span {
  background: white;
  position: relative;
  top: 10px;
  padding: 6px;
  font-size: 12px;
}

.mh-connDisconnLeaveMsg-type-Yes {
  color: #797979;
}

.mh-connDisconnLeaveMsg-type-No {
  color: #3a88b1;
}

.mh-connDisconnLeaveMsg-type-Leave {
  color: red;
}

.mh-connDisconnLeaveMsg-type-Yes {
  border-color: #a9a8a8;
}

.mh-connDisconnLeaveMsg-type-Leave {
  border-color: #ea5f5f;
}

.mh-connDisconnLeaveMsg-type-No {
  border-color: #6ec1ec;
}

.modal-body .kt-section__body .ui.card,
.ui.cards > .card {
  width: 267px !important;
}

.css-1qyrjaz {
  display: none;
}

.mh-oneChatContainer {
  padding-bottom: 3px !important;
}
/* End css for Chat Customization */
.yr-closebtn {
  position: relative;
}
.yr-closebtn .close {
  position: absolute;
  right: -6px;
  top: -6px;
  background-image: linear-gradient(45deg, #03bfa4, #0797a9);
  padding: 4px;
  border: 3px solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 80%;
  color: #fff;
  opacity: 1;
  font-weight: normal;
  line-height: 12px;
}
.delBtn {
  position: absolute;
  right: -30px;
  bottom: 10px;
}
.workSpaceAddField .kt-checkbox > span {
  left: auto;
}
.cusrsorpointer {
  cursor: pointer;
}

/* Start Review Via Chat */
.pd20 {
  padding: 20px !important;
}
.form-group.reviewlink {
  margin-bottom: 10px;
}
span.yr-rightchatMsg {
  background-color: #02c4a1;
  color: #ffffff;
  font-size: 13px;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 20px 0 0px !important;
  display: inline-block;
}
.yr-selectLocation a.yr-minusBtn {
  color: red;
  position: absolute;
  top: 10px;
  right: -16px;
}
.yr-selectLocation {
  position: relative;
}
a.yr-minusBtn.editbtn {
  color: blue;
  right: 4px;
}
.col-xs-12.yr-selectLocation input {
  width: 90%;
}

.workspace-automation .chatCompleted .input-group-text {
  border: none !important;
}

.inner-box footer button.btn.yr-round-btn {
  margin-right: 5px !important;
}
.inner-box footer button.btn.yr-round-btn:last-child {
  margin-right: 0px !important;
}
ul.yr-btneditable {
  list-style: none;
  padding-left: 0px;
  display: inline-flex;
}

ul.yr-btneditable li .yr-btnicon {
  position: absolute;
  top: -30px;
  right: 15px;
  display: none;
}

ul.yr-btneditable li {
  position: relative;
}

ul.yr-btneditable li:hover .yr-btnicon {
  display: inline-block;
}
.ui.secondary.pointing.menu .item {
  padding: 0.8em 0.8em;
}
.yr-sticky-header {
  position: sticky;
  top: 0;
  background: #e9eef2;
  padding: 20px 0;
}
.marginbt5 {
  margin-bottom: 10px;
}
.padtop-10 {
  padding-top: 10px;
}
.botbuilder-wrapper-class {
  border: 1px solid #cccccc;
  padding: 8px;
}
.botbuilder-toolbar-class.rdw-editor-toolbar {
  border: none;
}
.botbuilder-toolbar-class .rdw-option-wrapper {
  padding: 0px;
  min-width: 22px;
}

/*  ---------  API Instructions  -----  */
p.text-blue {
  color: #087ab5;
}

.api-div-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
}

.yr-icon-api-instructions:after {
  content: url(media/logos/icon-api-instructions.png);
  position: absolute;
  left: 0;
  top: 0px;
}
.yr-icon-api-instructions {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  position: relative;
  display: inline-block;
}
.socialReview .ui.input > input {
  background-color: gainsboro !important;
  padding-left: 5px !important;
}
.requestReviewForm .ui.input > input {
  padding-left: 5px !important;
}
/*  -----------------------------------  */

/*  -------------- Bot Builder Editor---------------------  */
.buttonlistbtbuilder .yr-btneditable button {
  margin-bottom: 18px;
}
.botbuilder-wrapper-class {
  padding: 0px !important;
  border: 0px !important;
}
.rdw-editor-toolbar {
  background: #e6e5e5 !important;
  /* border-bottom: 2px solid #cdcccc !important; */
}
.rdw-editor-main {
  padding: 0 8px 15px !important;
}
.rdw-option-wrapper {
  border: 1px solid #dddddd !important;
}
.botbuilder-toolbar-class {
  border-radius: 0 !important;
  margin-bottom: 0px !important;
}
.botbuilder-editor-class {
  border: 1px solid #ccc;
}

#sensitiveDataActivityLogParent b {
  font-weight: 600;
}
@media (min-width: 1025px) {
  body.kt-header--fixed {
    padding-top: 65px !important;
  }
  .kt-header--fixed .kt-header {
    height: 65px !important;
  }
  .kt-header-menu .kt-menu__nav {
    padding: 15px !important;
  }
}
/* marketiing email contact 2 */
.modal-content.white-bg.yr-campaigns-model {
  padding: 0 25px;
}
.yr-campaigns-model i.fa.fa-giftbox:after {
  background: url(media/icons/icon-gift.png);
}
.yr-campaigns-model i.fa.fa-coupon:after {
  background: url(media/icons/coupon.png);
}
.yr-campaigns-model i.fa.fa-marketing:after {
  background: url(media/icons/icon-marketing.png);
}
.yr-campaigns-model i.fa.fa-repair:after {
  background: url(media/icons/icon-plug.png);
}
.yr-campaigns-model .modal-header {
  padding: 1.25rem !important;
}
.yr-campaigns-model i.fa.fa-marketing:after,
.yr-campaigns-model i.fa.fa-repair:after,
.yr-campaigns-model i.fa.fa-coupon:after,
.yr-campaigns-model i.fa.fa-giftbox:after {
  content: "";
  width: 35px;
  height: 35px;
  position: absolute;
  background-size: 33px;
  background-repeat: no-repeat;
  left: 0;
}
.yr-campaigns-model .form-group {
  margin-bottom: 0rem;
  padding-left: 40px;
  position: relative;
}
span.yr-iconSec {
  position: absolute;
  left: -20px;
  top: 0px;
  display: inline-block;
}
.yr-campaigns-model .form-group {
  padding-left: 40px;
  position: relative;
}

select.form-control.yr-tableSelect {
  background: transparent;
  border: 0;
  padding: 0;
  height: auto;
}
/* Mail Playbooks */
#manage-user_wrapper .dataTables_length,
#manage-user_wrapper .dataTables_filter {
  display: none !important;
}
.yr-custom-menu ul {
  padding: 0px;
  list-style: none;
  margin: 0;
}

.yr-custom-menu {
  width: auto;
  min-width: auto;
  border: 1px solid #e0dede !important;
  padding: 0;
}

.yr-custom-menu ul li a {
  padding: 5px 10px;
  border-bottom: 1px solid #d1d1d1;
  display: block;
}

.dropdown-menu.yr-custom-menu.show ul li a:hover {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  color: #fff;
}

.nav-tabs.nav-tabs-line a.nav-link span i {
  font-size: 11px !important;
  padding-right: 5px;
}
.nav-tabs.nav-tabs-line a.nav-link span i.green {
  color: #17c117 !important;
}
.yr-ameplaybooks .kt-checkbox {
  height: 18px;
}
.yr-ameplaybooks tbody td {
  padding-right: 30px;
}
.yr-ameplaybooks .dataTable td > a {
  color: #6c7293;
}

.yr-custom-menu ul li a span {
  margin-left: 5px;
}
.yr-ameplaybooks table.dataTable thead th:last-child.sorting:before,
.yr-ameplaybooks table.dataTable thead th:last-child.sorting:after {
  display: none;
}

.kt-menu__item .yr-navicon-email:before {
  background-image: url(media/menu-icon/unselected/email-icon.svg);
}
.kt-menu__item .yr-navicon-calllist:before {
  background-image: url(media/menu-icon/unselected/call-list.svg);
}

.kt-menu__item--active .yr-navicon-calllist:before {
  background-image: url(media/menu-icon/selected/call-list-white.svg);
}
.kt-menu__item--active .yr-navicon-email:before {
  background-image: url(media/menu-icon/selected/email-icon-white.svg);
}

.automatedMarketing .yr-line-input .form-control {
  border: 0;
  border-bottom: 2px solid #aaacb3;
  border-radius: 0;
  padding: 0;
  /* margin-bottom: 30px; */
  margin-top: 10px;
  max-width: 300px;
}

/*  -------------- Automate Marketing ---------------------  */

/*  -------------- Bot Builder Editor---------------------  */
@media (min-width: 1025px) {
  body.kt-header--fixed {
    padding-top: 65px !important;
  }
  .kt-header--fixed .kt-header {
    height: 65px !important;
  }
  .kt-header-menu .kt-menu__nav {
    padding: 15px !important;
  }
}
div#sensitiveDataActivityLogParent .ui.segment {
  margin: 0 auto;
}
/*  -------------- Bot Builder Editor---------------------  */

/*  -------------- Automate Marketing Start---------------------  */
.automatedMarketing .yr-workspace > ul > li {
  height: auto;
  margin: 10px 0;
  padding-bottom: 20px;
  padding-top: 20px;
}
.automatedMarketing .table tbody td:first-child {
  width: auto !important;
}
.automatedMarketing .ui.table thead th {
  color: #fff;
  background: #00d29f;
  font-weight: 500;
}
.automatedMarketing .ui.table tr td {
  background: #eafefc;
  border-bottom: 1px solid #8f9799;
  border-left: 1px solid #8f9799;
}
.automatedMarketing .ui.table tr th {
  border-left: 1px solid#8f9799;
}
.automatedMarketing .ui.table tr td:last-child {
  border-right: 1px solid #8f9799;
}
/* Page 2 */
.sapretline {
  height: 2px;
  background: #d4cfcf;
  max-width: 27%;
  margin-bottom: 30px;
  margin-top: 40px;
}
.custom-file-upload {
  display: inline-block;
  padding: 40px 12px;
  white-space: nowrap;
  color: #8c8989;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  text-decoration: underline;
  font-weight: 400;
  overflow: hidden;
  text-align: center;
}
.uploadfile {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
}
i.fa.fa-cloud-upload:after {
  content: "";
  width: 50px;
  height: 50px;
  background: url(media/icons/upload.png);
  position: absolute;
  background-size: 50px;
  left: 0;
}
i.fa.fa-cloud-upload {
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
}
/* Page 3 */
.automatedMarketing .table tbody td:first-child {
  width: auto !important;
}
.automatedMarketing .ui.table thead th {
  color: #fff;
  background: #00d29f;
  font-weight: 500;
  padding-left: 15px;
  border-left: 1px solid#8f9799;
}
.automatedMarketing .ui.table tr td {
  background: #eafefc;
  border-bottom: 1px solid #8f9799;
  border-left: 1px solid #8f9799;
  padding-left: 15px;
}
span.editicon {
  padding-right: 5px;
}
.automatedMarketing .ui.table tr td:last-child,
.automatedMarketing .ui.table tr th:last-child {
  border-right: 1px solid #8f9799;
}
span.infoicon {
  float: right;
}
span.infoicon i.info.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 9px;
  text-align: center;
  position: relative;
  box-sizing: content-box;
}
select.form-control.yr-tableSelect {
  background: transparent;
  border: 0;
  padding: 0;
  height: auto;
}
/* Mail Playbooks */
#manage-user_wrapper .dataTables_length,
#manage-user_wrapper .dataTables_filter {
  display: none !important;
}
.yr-custom-menu ul {
  padding: 0px;
  list-style: none;
  margin: 0;
}

.yr-custom-menu {
  width: auto;
  min-width: auto;
  border: 1px solid #e0dede !important;
  padding: 0;
}

.yr-custom-menu ul li a {
  padding: 5px 10px;
  border-bottom: 1px solid #d1d1d1;
  display: block;
}

.dropdown-menu.yr-custom-menu.show ul li a:hover {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  color: #fff;
}

.nav-tabs.nav-tabs-line a.nav-link span i {
  font-size: 11px !important;
  padding-right: 5px;
}
.nav-tabs.nav-tabs-line a.nav-link span i.green {
  color: #17c117 !important;
}
.yr-ameplaybooks .kt-checkbox {
  height: 18px;
}
.yr-ameplaybooks tbody td {
  padding-right: 30px;
}
.yr-ameplaybooks .dataTable td > a {
  color: #6c7293;
}

.yr-custom-menu ul li a span {
  margin-left: 5px;
}
.yr-ameplaybooks table.dataTable thead th:last-child.sorting:before,
.yr-ameplaybooks table.dataTable thead th:last-child.sorting:after {
  display: none;
}

.kt-menu__item .yr-navicon-email:before {
  background-image: url(media/menu-icon/unselected/email-icon.svg);
}
.kt-menu__item .yr-navicon-calllist:before {
  background-image: url(media/menu-icon/unselected/call-list.svg);
}

.kt-menu__item--active .yr-navicon-calllist:before {
  background-image: url(media/menu-icon/selected/call-list-white.svg);
}
.kt-menu__item--active .yr-navicon-email:before {
  background-image: url(media/menu-icon/selected/email-icon-white.svg);
}

.automatedMarketing .yr-line-input .form-control {
  border: 0;
  border-bottom: 2px solid #aaacb3;
  border-radius: 0;
  padding: 0;
  margin-bottom: 30px;
  margin-top: 10px;
  max-width: 300px;
}

.mh-mobileHeadrProfileInfo {
  font-size: 15px;
  margin-right: 5px;
}

/*  -------------- Automate Marketing ---------------------  */
/*  -------------- Bot Builder Editor---------------------  */
@media (min-width: 1025px) {
  body.kt-header--fixed {
    padding-top: 65px !important;
  }
  .kt-header--fixed .kt-header {
    height: 65px !important;
  }
  .kt-header-menu .kt-menu__nav {
    padding: 15px !important;
  }
}
div#sensitiveDataActivityLogParent .ui.segment {
  margin: 0 auto;
}
/*  -------------- Bot Builder Editor---------------------  */

/*----------------- Not Recognize section -----------------------*/

.yr-cat-columnBox {
  border: 1px solid;
  width: 230px;
  min-width: 230px;
  border-left: 0px;
  background: #eafefc;
}

.yr-title-select {
  background: #01d19f;
  padding: 15px 10px;
  color: #fff;
  display: flex;
  /* align-items: center; */
}

span.editicon select {
  color: #fff !important;
  background: #01d19f !important;
  border: 0px;
}
.yr-cat-columnBox ul {
  padding: 0;
  list-style: none;
  margin: 0;
  max-height: 200px;
  background: #eafefc;
  overflow: auto;
}
.yr-cat-columnBox ul li {
  border-top: 1px solid;
  padding: 10px;
  height: 40px;
}
.yr-cat-column {
  display: flex;
  max-width: 1200px;
}
.yr-cat-columnBox:first-child {
  border-left: 1px solid;
}
.automatedMarketing .ui.table tr td:last-child,
.automatedMarketing .ui.table tr th:last-child {
  border-right: 1px solid #8f9799;
}
.automatedMarketing span.infoicon {
  display: inline-flex;
  justify-content: flex-end;
}
.automatedMarketing span.infoicon:last-child {
  max-width: 40px;
}
.yr-title-select span {
  flex: 1;
}
.automatedMarketing span.infoicon i.fa.fa-info {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 9px;
  text-align: center;
  position: relative;
  box-sizing: content-box;
  line-height: 14px;
}
.automatedMarketing select.form-control.yr-tableSelect {
  background: transparent;
  border: 0;
  padding: 0;
  height: auto;
}
.automatedMarketing .yr-workspace {
  overflow: auto;
}

.cdyNRy .blockbuilder-branding {
  display: none !important;
}


/*Added by sk start*/
.sk-redirectURL .ui.input > input {
  padding-left: 5px !important;
}
/*------------------- Not Recognize section ------------------*/

/*Added by sk start*/
.sk-redirectURL .ui.input > input {
  padding-left: 5px !important;
}

/*------------------- To remove css from post card------------------*/
.reset-this p{
  color : #fff !important;
 
}

/* rohit extra css */
.fix-btn-size {
  height: 36px;
  font-size: 13px;
}
.white-font {
  color: #fff !important;

}
white-font > a{
  color: #fff !important;
  
}
.btn:hover {
  color:#fff;
  text-decoration: none;
}
.diff{
  margin-left: 20px !important;
  border: 2px solid !important;
}



.yr-cancelbtn:hover{
  color: #333;
}

.react-datepicker,
.react-datepicker-time__caption{
    font-size:13px;
}

.react-datepicker-time__input > input.react-datepicker-time__input{
  padding: 5px 5px 5px 10px;
  font-size: 15px;
  border: 1px solid #444;
  color: #444;
  border-radius: 5px;
  margin-left: 0;
}

.react-datepicker__input-time-container{
  margin: unset !important;
  padding: 10px 20px;
}

.react-datepicker__month{
  padding: 10px;
}


/* css added by aslam for chatbot client image */
.small-img-logo.zy--img > img ,
.yr-chatlogo-mini.zy--img > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: unset !important;
    max-height: unset !important;
}

.yr-chatlogo-mini.zy--p0 {
    padding: 0 !important;
    overflow: hidden;
}

.yr-body-top.zy--body__img img{
    object-fit: contain;
}