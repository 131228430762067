@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
:root {
  --black-color: #333;
  --dark-color: #333;
  --grey-color: #808080;
  --grey-light-color: #b3b1b1;
  --white-color: #fff;
  --green-color: #00dd99;
  --green-light: #00ff99;
  --blue-color: #087ab6;
  --font-montserrat: "Montserrat", sans-serif;
  --primary-blue: #0784b3;
}
body {
  color: #959393 !important;
  font-family: var(--font-montserrat) !important;
}
html,
body {
  height: 100%;
  background-color: #fff;
  color: #959393 !important;
  font-family: var(--font-montserrat);
}
h2 {
  font-family: var(--font-montserrat);
  font-weight: 500;
}
p {
  margin: 0 0 1em;
  line-height: 1.5;
  margin-top: 1rem !important;
  margin-bottom: 1rem;
}
a.btn.btn-icon.dropdown-toggle.yr-managebtn {
  background-image: linear-gradient(45deg, #087ab6, #00d39e);
  box-shadow: 0px 4px 8px #b3b3b3 !important;
  width: auto;
  height: auto;
  padding: 5px 10px;
  color: #fff;
  border-radius: 20px;
  min-width: 90px;
  border: 0px;
}
.ui.sortable.table .btn.btn-icon.yr-managebtn {
  height: auto !important;
  width: auto !important;
}
.mh-playbook-table .btn.btn-icon {
  justify-content: end !important;
}

.mh-playbook-table .dropdown-menu.show {
  top: 2px !important;
  left: 0px !important;
}

.mh-cusrorAuto {
  cursor: auto !important;
}

.mh-bucketNameTag {
  width: auto;
  background: #387ab5;
  color: white;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  margin: 1px;
  line-height: 0.9;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mh-playbook-table i {
  margin-right: 2px;
  font-size: 11px;
}

.gjs-cv-canvas {
  left: auto !important;
  right: 0px;
}

.gjs-pn-views-container {
  left: 0px !important;
  margin-top: 40px;
}

.gjs-fonts::before {
  font-size: 3em !important;
}

.gjs-block {
  margin: 2px 2.5% 5px !important;
  min-height: 60px !important;
}

.gjs-pn-views-container {
  padding: 0px !important;
}

#gjs-rte-toolbar,
.gjs-bg-main,
.gjs-block,
.gjs-clm-select option,
.gjs-clm-tags .gjs-sm-colorp-c,
.gjs-editor,
.gjs-mdl-dialog,
.gjs-nv-item .gjs-nv-title-c,
.gjs-off-prv,
.gjs-pn-panel,
.gjs-select option,
.gjs-sm-sector .gjs-sm-colorp-c,
.gjs-sm-select option,
.gjs-sm-unit option,
.sp-container {
  background-color: #e9eef2 !important;
}

.gjs-blocks-cs {
  background-color: #e6e6e6 !important;
}
.gjs-block {
  color: while !important;
}

#kt_portlet_base_demo_1_1_tab_content .fa {
  color: #fff !important;
  font-size: 14px !important;
}

.gjs-two-color,
#kt_portlet_base_demo_1_1_tab_content .fa {
  color: #1d1735 !important;
}

.gjs-pn-btn {
  padding-top: 9px !important;
}

.cke_notifications_area {
  position: fixed !important;
  left: 40% !important;
  top: 100px !important;
}

.cke_notification_message {
  color: white !important;
}

/* .gjs-frame-wrapper{
    top:4px !important;
} */

.gjs-pn-views {
  width: 16%;
  border-bottom: none !important;
}
.gjs-pn-options {
  right: 20%;
}

.gjs-one-bg {
  background-color: #e9eef2 !important;
}

.gjs-pn-panels {
  width: 100%;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer #gjs-sm-close-layer,
.gjs-clm-tags .gjs-sm-property .gjs-sm-layer #gjs-sm-close-layer {
  opacity: 1;
}

.gjs-sm-layer > #gjs-sm-move {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer #gjs-sm-close-layer {
  top: 4px;
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-layer > #gjs-sm-preview-box {
  right: 24px;
}
.gjs-four-color {
  color: #000000;
}

.gjs-sm-sector .gjs-sm-stack #gjs-sm-add {
  color: #000 !important;
}
.gjs-sm-sector .gjs-sm-property {
  padding: 0px !important;
}
.gjs-mdl-dialog {
  background-color: #fafafa !important;
}
.gjs-mdl-header {
  color: #727272;
}
.gjs-mdl-title {
  font-size: 16px;
  font-weight: 600;
}

button.gjs-btn-prim.gjs-btn-import {
  background: #cfcdcd;
  padding: 10px 20px;
  color: #333;
  margin-top: 2rem;
}
.gjs-mdl-dialog.gjs-one-bg.gjs-two-color .form-group {
  margin-bottom: 0rem;
}

#kt_portlet_base_demo_1_1_tab_content
  i.gjs-layer-caret.fa.fa-chevron-right.fa-chevron-down {
  padding: 1px 0;
  font-size: 10px !important;
}

.sp-container {
  position: fixed !important;
  top: 267px !important;
  left: 50% !important;
}

#kt_portlet_base_demo_1_1_tab_content .gjs-toolbar .fa {
  color: #ffffff !important;
  font-size: 12px !important;
}

.gjs-mdl-content .form-control {
  border: 1px solid #aaaaaa;
}

.gjs-mdl-content .form-group label {
  margin-top: 10px;
  font-size: 1.12rem;
  font-weight: 500;
  color: #404041;
}

.gjs-sm-icon {
  color: black;
}

#gjs-sm-input-holder option {
  color: black !important;
}

.gjs-sm-sector .gjs-sm-field input,
.gjs-clm-tags .gjs-sm-field input,
.gjs-sm-sector .gjs-clm-select input,
.gjs-clm-tags .gjs-clm-select input,
.gjs-sm-sector .gjs-clm-field input,
.gjs-clm-tags .gjs-clm-field input,
.gjs-sm-sector .gjs-sm-field select,
.gjs-clm-tags .gjs-sm-field select,
.gjs-sm-sector .gjs-clm-select select,
.gjs-clm-tags .gjs-clm-select select,
.gjs-sm-sector .gjs-clm-field select,
.gjs-clm-tags .gjs-clm-field select {
  color: black !important;
}

.gjs-f-b1 {
  order: 1;
}
.gjs-f-b2 {
  order: 2;
}
.gjs-f-b3 {
  order: 3;
}
.gjs-f-b37 {
  order: 4;
}
.gjs-f-button {
  order: 5;
}
.gjs-f-divider {
  order: 6;
}

.gjs-f-text {
  order: 7;
}
.gjs-f-h1p {
  order: 8;
}

.gjs-f-image {
  order: 9;
}
.fa-quote-right {
  order: 11;
}
.fa-link {
  order: 12;
}

.fa-th {
  order: 13;
}

.fa-th-list {
  order: 14;
}

.fa-youtube-play {
  order: 10;
}

.fa-youtube-play:before {
  font-size: 28px;
}

#gjs-tools {
  z-index: 888 !important;
}

#gjs-tools {
  z-index: 777 !important;
}

.gjs-btnt.gjs-pn-active,
.gjs-color-active,
.gjs-pn-btn.gjs-pn-active,
.gjs-pn-btn:active,
.gjs-block:hover {
  color: #087ab6 !important;
}

#kt_portlet_base_demo_1_1_tab_content .fa:hover {
  color: #087ab6 !important;
}
.gjs-pn-commands {
  box-shadow: none !important;
}

.gjs-radio-item label {
  margin: 0;
}

.fa-quote-right:before {
  font-size: 17px;
}

.fa-chain:before,
.fa-link:before {
  font-size: 20px;
}

.fa-th:before {
  font-size: 21px;
}

.fa-th-list:before {
  font-size: 21px;
}

.gjs-pn-buttons .fa-bars {
  display: none;
}

.gjs-pn-options {
  right: 11% !important;
}

.gjs-pn-views {
  width: 12% !important;
}
.active_branch {
  border: 1px solid rgb(15, 98, 254);
}
.checboxrules {
  margin: 0px !important;
}
.textrule {
  vertical-align: top;
}
.calllistrule {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}
.calllistsecond {
  padding-bottom: 10px;
}

.otherbuttonlinks {
  max-width: 150px;
}
.activebuttonlink {
  background: linear-gradient(45deg, #087ab6, #00d39e) !important;
  color: #fff !important;
  padding: 5px;
  border-radius: 5px;
}

/*//@Rohit*/
.analiytics-link-email > a {
  color: #212529 !important;
}


.postcard--list{
  list-style: none;
  padding:0;
  /* justify-content: space-between; */
}
.postcard--list .postcard--listitem{
  margin:0 5px 10px;

  width:32%;
  height: 160px;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #0004;
  overflow: hidden;
  transition: .4s ease;
}
.postcard--list .postcard--listitem > a{
  height: 100%;
}

.postcard--list li.postcard--listitem a img{
  width: 100%;
  height: 100%;

}

.postcard--list li.postcard--listitem:hover{
  box-shadow: 0 0 0 2px var(--primary-blue);
  pointer-events: all;
}


.postcard--listitem.border_highlighted{
  box-shadow: 0 0 0 2px var(--primary-blue);
  pointer-events: all;
}




:focus{
  outline: none;
  box-shadow: 0 10px 20px -5px #0000;
}

.padding-lg{
  padding: 25px 40px;
  box-shadow: 0 10px 20px -5px #0005;
  margin-bottom: 3rem;
}

.padding-lg > i{
  font-size: x-small;
  line-height: .5;
}

.fs-btn{
  font-size: 12px !important;
}

.upload-img-wrapper{
  width: 100%;
  height: 100px;
  border-radius: 10px;
  background-color: #efefef;
  margin-top: calc(1rem/2);
  margin-bottom: 1rem;
}

.upload-img-wrapper input{
  opacity: 0;
  width: 100%;
  height: 100%;
}

.form-control:focus{
  box-shadow: none;
}

.color-input{
  height: 30px;
}

input[type=color]{
  -webkit-appearance: textfield;
}
input[type=color]::-webkit-color-swatch-wrapper {
  padding: 0; 
}
input[type=color]::-webkit-color-swatch {
  border: 1px solid white;
}

.color-input .form-control{
  padding:0 !important;
  background-color: transparent;
  outline: none;
  border: none;
}

.color-input span{
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  width: 50px;
}

.border-gray{
  border:1px solid #acacac !important;
}

.rounded-30{
  /* padding-top: 20px;
  padding-bottom: 20px; */
  border-radius: 10px !important;
}





/*-----------------------------------*/

:root{
  --dark-color: #333;
  --grey-color: #808080;
  --light-color: #b3b1b1;
  --extra-light: #efefef;
  --white-color: #fff;
  --success-color: #00dd99;
  --green-light: #00ff99;
  --primary-color: #087ab6;
  --font-poppins: 'Poppins', sans-serif;

}


body{
  font-family: var(--font-poppins);
}

h1,h2,h3,h4,h5,h6{
  font-weight: 500;
}

ul{
  list-style: none;
  padding-left: 0;
}

select:focus{
  box-shadow: 0 0 0 1px  var(--primary-color) !important;
}


.text-blue{
  color: var(--primary-color);
}
.zyra--primary{
  background-color: var(--primary-color);
  color: #fff;
}

.zyra--btn.zyra--primary-gradient{
  background-image: linear-gradient(45deg, #087ab6 , #00d39e);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.zyra--btn.zyra--primary-outline{
  background: #fff;
  color: var(--primary-color);
  border:1px solid var(--primary-color) !important;
  transition: .5s ease;
}

.zyra--btn.zyra--primary-outline:hover{
  background-color: var(--primary-color);
  color: #fff;
}

.zyra--btn{
  border: 0 !important;
  height: 38px;
  border-radius: 5px;
  white-space: nowrap;
  padding-left:1rem;
  padding-right:1rem;
}

.zyra--light{
  background-color: var(--extra-light);
}


.radius-30{
  border-radius: 30px;
}


.zyra-fs-14{
  font-size: 12px;
  opacity: .4;
}

/* css added by aslam for postcard image dimension editing  */
.dynamicImg{
  object-fit: contain;
  object-position: center;
}