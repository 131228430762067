.pl--35 .ui.input input{
    padding-right: 35px !important;
    color: #959393 !important;
}

.pl--35 .ui.input{
    overflow: hidden;
}

.kt-input-icon > .kt-input-icon__icon.sr--iconBox{
    top:2px;
}

.sr--pHolder{
    cursor: pointer;
    background: #fff0;
    padding:.75rem 1rem;
    right: 0 !important;
    top: 0 !important;
    border-radius: 0 .28571429rem .28571429rem 0;
}

#teamMemberCallList .css-ik6y5r > div{
    border:1px solid #eee;
    border-radius: 4px;
}

#teamMemberCallList .css-48ayfv, #teamMemberCallList .css-1r4vtzz{
    width: 310px !important;
    display: flex;
    /* margin: auto; */
}

#teamMemberCallList .css-ik6y5r > div{
    max-height: 145px !important;
}

.dp--height{
    height: 200px;
}

.dp--height.maxHeight-320{
    height: 264px !important;
}

#teamMemberCallList > div > button > span{
    font-size: 12px;
}
#teamMemberCallList > div > button + div,
#teamMemberCallList{
    max-width: 310px;
    width: 100%;
}

#teamMemberCallList button span + span{
    position: relative;
    left: -18px;
    color: #2d7fb5 !important;
}

#teamMemberCallList button span + span svg{
    transform: scale(1.5);
}

#teamMemberCallList button + div {
    /* left: 50%;
    transform: translateX(-50%);
    width: 310px; */
}



#teamMemberCallList ~ .addicon{
    top:12px !important;
    left: 296px !important;
}

#teamMemberCallList ~ .addicon > div > img{
    width: 14px;
    height: 14px;
}

#teamMemberCallList .css-151xaom-placeholder,
#teamMemberCallList .css-1vr111p-option{
    color: #959393 !important;
}

#teamMemberCallList .css-1qprcsu-option{
    background-color: transparent;
    color: #959393 !important;
    font-weight: unset;
}


#notes_team_member{
    color: #959393 !important
}


#teamMemberCallList .css-1qprcsu-option:first-child,
#teamMemberCallList .css-1qprcsu-option:first-child input{
    cursor: not-allowed;
}