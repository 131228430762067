.pdf-preview p{
    color: #333 !important; 
}
.pdf-preview{
    padding: 4rem 3rem;
    border: 1px solid #0004;
    position: relative;
    max-width:900px;
    margin:1rem auto 0;
}

.pdf-preview span.status{
    position: absolute;
    top: 0;
    font-family: sans-serif;
    left: 0;
    background-color: #00d076;
    width: 90px;
    height: 25px;
    font-size: 13px;
    text-transform: capitalize;
    letter-spacing: 1px;
    line-height: 25px;
    text-align: center;
    color: white;
    transform: rotate(-45deg) translateX(-22px) translateY(10px);
}
.pdf-preview span.status i:first-child{
    position: absolute;
    left: -25px;
    top: -.5px;
    border-right: 12.75px solid #00d076;
    border-bottom: 12.75px solid #00d076;
    border-top: 12.75px solid #0000;
    border-left: 12.75px solid #0000;
}

.pdf-preview span.status i:first-child:before{
    content: '';
    position: absolute;
    bottom:-15px;
    left: -16px;
    transform: rotate(-45deg);
    border-right: 3.75px solid #0000;
    border-bottom: 3.75px solid #04aa62;
    border-top: 3.75px solid #0000;
    border-left: 3.75px solid #0000;
}

.pdf-preview span.status i:last-child{
    position: absolute;
    right: -24px;
    top: -.5px;
    border-left: 12.75px solid #00d076;
    border-bottom: 12.75px solid #00d076;
    border-top: 12.75px solid #0000;
    border-right: 12.75px solid #0000;
}

.pdf-preview span.status i:last-child:before{
    content: '';
    position: absolute;
    bottom:-15px;
    right: -15px;
    transform: rotate(45deg);
    border-right: 3.75px solid #0000;
    border-bottom: 3.75px solid #04aa62;
    border-top: 3.75px solid #0000;
    border-left: 3.75px solid #0000;
}

.pdf-preview p.text-blue{
    color: #23b5f0 !important;
    margin: 5px 0 0 !important;
}

.pdf-preview .table th,
.pdf-preview .table td{
    font-family: sans-serif;
    font-size: 9pt !important;
    padding: 8px !important;
    text-align: left !important;
}

.pdf-preview .table.no-padding td,
.pdf-preview .table-overview td,
.pdf-preview .item-list th:last-child,
.pdf-preview .item-list td:last-child{
    text-align: right !important; 
}

.pdf-preview .table.item-list{
    margin-top: 30px !important;
}

.pdf-preview .table.no-padding td{
    padding-bottom: 0 !important;
    padding-right: 0 !important;
}

.pdf-preview .table th{
    font-weight: 600;
    color: #fff !important;
}

.pdf-preview .table td{
    color: #333 !important;
}

.pdf-preview .table td.text-danger{
    color: #ff0000 !important;
}

.w-60{
    width: 60%;
}

.pdf-preview .small-title{
    font-size: 8pt !important;
    color: #333 !important;
    font-family: sans-serif !important; 
}


