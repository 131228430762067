@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
:root {
    --black-color: #333;
    --dark-color: #333;
    --grey-color: #808080;
    --grey-light-color: #b3b1b1;
    --white-color: #fff;
    --green-color: #00dd99;
    --green-light: #00ff99;
    --blue-color: #087ab6;
    --font-montserrat: 'Montserrat', sans-serif;
}

body {
    color: #959393 !important;
    font-family: var(--font-montserrat);
}

#manage-user_wrapper .dataTables_length, #manage-user_wrapper .dataTables_filter {
	display: none !important;
}
/*  */

.automatedMarketing .yr-line-input .form-control{
    border: 0;
    border-bottom: 2px solid #aaacb3;
    border-radius: 0;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 10px;
    max-width: 300px;
}
.automatedMarketing .custom-file-upload {
    display: inline-block;
    padding: 40px 12px;
    white-space: nowrap;
    color: #8c8989;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 16px;
	line-height: 16px;
    width: 100%;
    text-decoration: underline;
    font-weight: 400;
    overflow: hidden;
	text-align: center;
}
.automatedMarketing .uploadfile {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.automatedMarketing .table tbody td:first-child {
    width: auto !important;
}
.automatedMarketing .ui.table thead th {
    color: #fff;
    background: #00d29f;
    font-weight: 500;
	padding-left: 15px;
	border-left: 1px solid#8f9799;
}
.automatedMarketing .ui.table tr td {
    background: #eafefc;
    border-bottom: 1px solid #8f9799;
    border-left: 1px solid #8f9799;
	padding-left: 15px;
}
span.editicon {
    padding-right: 5px;
}
.automatedMarketing .ui.table tr td:last-child, .automatedMarketing .ui.table tr th:last-child {
    border-right: 1px solid #8f9799;
}
.automatedMarketing span.infoicon {
    float: right;
}
.automatedMarketing span.infoicon i.fa.fa-info {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
    font-size: 9px;
    text-align: center;
    position: relative;
    box-sizing: content-box;
	line-height: 14px;
}
.automatedMarketing select.form-control.yr-tableSelect {
    background: transparent;
    border: 0;
    padding: 0;
	height: auto;
}
.automatedMarketing .yr-workspace {
    overflow: auto;
}
.automatedMarketing .ui.table thead th{
    min-width: 180px;
}
.automatedMarketing .kt-checkbox.chat-checkbox {
    white-space: inherit !important;
}

.yr-workspace .uploadfile i.fa.fa-cloud-upload {
    width: 50px;
    height: 50px;
	position: relative;
}



/*  */
.yr-custom-menu ul {
    padding: 0px;
    list-style: none;
    margin: 0;
}

.yr-custom-menu {
    width: auto;
    min-width: auto;
    border: 1px solid #e0dede !important;
    padding: 0;
}

.yr-custom-menu ul li a {
    padding: 5px 10px;
    border-bottom: 1px solid #d1d1d1;
    display: block;
}

.dropdown-menu.yr-custom-menu.show ul li a:hover {
    background-image: linear-gradient(45deg, #087ab6, #00d39e );
    color: #fff;
}

.yr-ameplaybooks .nav-tabs.nav-tabs-line a.nav-link span i {
    font-size: 11px !important;
    padding-right: 5px;
}

.yr-ameplaybooks span i.gray {
    color: #cacaca !important;
}
.yr-ameplaybooks span i.green {
	color:#00b848 !important;
} 
.yr-ameplaybooks span i.orange {
    color: #ffa901 !important;
}
.yr-ameplaybooks table span i.fa.fa-circle{
	font-size: 10px;
}
.yr-ameplaybooks .kt-checkbox, .yr-manage-contact .kt-checkbox{
height: 18px;
}
/* .yr-ameplaybooks tbody td{
	padding-right: 30px;
} */
.yr-ameplaybooks .table tbody tr td:first-child, .yr-ameplaybooks .table thead tr th:first-child {
    min-width: 30px !important;
    width: 40px !important;
    padding-right: 0.75rem;
}
.yr-ameplaybooks .dataTable td>a{
    color: #6c7293;
}

.yr-custom-menu ul li a span {
    margin-left: 5px;
}
.yr-ameplaybooks table.dataTable thead th:last-child.sorting:before, .yr-ameplaybooks table.dataTable thead th:last-child.sorting:after{
display: none;
}
.yr-titleSelect{
    display: flex;
    align-items: center;
}
.yr-allselect select {
    border: 0px;
    border-bottom: 1px solid #18a6d8;
    border-radius: 0;
    color: #179ad2 !important;
    font-size: 16px;
}
.yr-allselect {
    margin-left: 30px;
}
.yr-allselect span {
    font-size: 18px;
}
a.btn.btn-icon.dropdown-toggle.yr-managebtn {
    background-image: linear-gradient(45deg, #087ab6 , #00d39e);
    box-shadow: 0px 4px 8px #b3b3b3;
    width: auto;
    height: auto;
    padding: 5px 10px;
    color: #fff;
    border-radius: 20px;
    min-width: 90px;
}
.yr-managebtn-mc {
    background-image: linear-gradient(45deg, #138190 , #138190) !important;
    width: auto;
    height: auto;
    padding: 5px 10px;
    color: #fff !important;
    border-radius: 20px;
    min-width: 90px;
}
.green{color: #15b719 !important;}
.lightgreen{color:#40e245 !important;}
.orange{color: #ff8100 !important;}
.yr-linkcolor{color: blue !important;}
.yr-detailedtab a.nav-link span {
    display: inline-block;
    padding: 0px 10px;
    border: 1px solid;
    margin-left: 10px;
    border-radius: 20px;
    font-size: 14px;
}
.nav-tabs.nav-tabs-line.yr-detailedtab .nav-link{
    border-bottom: 1px solid #00000024;
}
.table.yr-sms-configration tbody td:first-child {
    width: auto !important;
    min-width: auto !important;
}
/* Details Analytics */
.yr-details-analy .table tbody td:first-child {
    min-width: auto;
}
.yr-details-analy tbody td:first-child{
width: 40%
}
.yr-selectBtnSec{
    display: flex;
    flex-wrap: wrap;
    }
.yr-gradientBorderbtn{
    background: linear-gradient(90deg, #00d39e , #087ab6);
    box-shadow: 0px 4px 8px #b3b3b3 !important;
    border-radius: 20px;
    color: #333;
    display: inline-block;
    font-size: 16px;
    padding: 3px;
    text-decoration: none;
    min-width: 130px;
    border: 0;
}
.yr-details-analy .yr-gradientBorderbtn select{
    border-radius: 20px;
}
.yr-contactengagement ul {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}



.yr-contactengagement ul li {
    flex: 1;
    border: 1px solid #d8d8d8;
    padding: 10px;
    text-align: center;
    border-left: 0;
    min-height: 264px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    transition: .4s ease;
}

.yr-contactengagement ul li:hover,
.yr-contactengagement ul li:first-child:hover{
    box-shadow:  0 0px 30px -5px #0004;
}

.yr-contactengagement ul li:first-child {
    border-left: 1px solid #d8d8d8;
}
.yr-deliverrates tr,.yr-deliverrates tr td {
    border: 1px solid #d8d8d8;
}


.yr-deliverrates tr td.pointer:hover{
    box-shadow: 0 0 20px -5px #0004;
}


.yr-config-setting tbody tr td i {
    font-size: 18px;
}
.yr-dr-box h3 {
    font-size: 25px;
    font-weight: 600;
    line-height: 25px;
}
.yr-deliverrates .yr-dr-box h3 {
    font-size: 20px;
}
.yr-dr-box {
    text-align: center;
}
.pd-15 {
    padding: 15px;
}
.yr-dr-box strong {
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
}
.yr-dr-box p {
    margin-bottom: 6px;
}
.yr-dr-box a {
    color: #02bea3;
    text-decoration: underline;
}
.yr-contactengagement>h3, .yr-deliverrates>h3 {
    margin-bottom: 20px;
    font-size: 16px;
    margin-top: 20px;
}
.yr-deliverrates, .yr-contactengagement {
    margin-bottom: 40px;
}
.yr-loader-area {
    padding: 15px;
}
.info-icon:hover .clicktoshow{
    opacity: 1;
        visibility: visible;
    }
    .clicktoshow{
        opacity: 0;
        visibility: hidden;
    position:relative;
        transition: top 0.3s cubic-bezier(0.25, 0.1, 0.11, 1.29), left 0.3s cubic-bezier(0.25, 0.1, 0.11, 1.29), opacity 0.3s ease;
    }
    .clicktoshow{
        position: absolute;
        background: #343738;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgba(0,0,0,0.2);
        padding: 10px 20px;
        -webkit-font-smoothing: antialiased;
        line-height: 1.4;
        font-size: 10px;
        color: #fff;
        word-break: break-word;
        text-align: center;
        display: block;
    }
hr.loader {
    height: 2px;
    background: #02c1a3;
}
.yr-manage-contact-popup .yr-cancelbtn {
    font-size: 13px;
    min-width: 120px;
    height: 43px;
    box-shadow: 0px 4px 8px #b3b3b3 !important;
    }
.yr-manage-contact-popup .form-group {
    margin-bottom: 1rem;
}
.yr-manage-contact-popup .dropdown-menu.inner > li:hover > a {
    background: #f7f8fa;
    background-image: linear-gradient(45deg, #087ab6 , #00d39e) !important;
}
.yr-manage-contact-popup .dropdown-menu.inner > li:hover > a .text, .yr-manage-contact-popup .dropdown-menu.inner > li:hover > a .bs-ok-default.check-mark {
    color: #ffffff;    
}
.yr-manage-contact-popup .dropdown-menu.inner > li > a .text{
    margin-left: 15px;
    margin-right: 0px;
}
.yr-manage-contact-popup .dropdown-menu {
    border: 1px solid #bbbbbb !important;
    padding: 0px;
}
.yr-manage-contact-popup .dropdown-menu .show {
    border: 0px!important;
}
.yr-manage-contact-popup span.bs-ok-default.check-mark {
    position: absolute;
    top: 16px;
    display: inherit !important;
    left: 10px;
}
.yr-manage-contact-popup .bootstrap-select .bs-ok-default:after {
    content: '';
    display: block;
    width: .5em;
    height: .5em;
    border-style: solid;
    border-width: 0 0.16em 0.16em 0;
    transform: rotate(-45deg);
}
h3.sm-b-title {
    font-weight: 700;
    font-size: 18px;
}
table.yr-bucket-table td span {
    display: block;
    font-size: 11px;
}
table.yr-bucket-table td span.b {
    font-weight: 600;
}
table.yr-bucket-table td span.t-sm{
    font-size: 13px;
}
table.table.yr-bucket-table .btn {
    background: #e8e8e8;
    width: auto !important;
    padding: 0px 12px;
    height: 30px !important;
    line-height: 25px;
    margin-right: 10px;
}
table.table.yr-bucket-table .btn span {
    display: inline-block;
    border-left: 1px solid #f5f5f5;
    width: 5px;
    height: 27px;
    margin-left: 10px;
}
.yr-ep-icon {
    display: inline-flex;
}
.yr-ep-icon a {
    margin-right: 10px;
    color: #b3b1b1;
}
.yr-mc-select select {
    border: 0px;
    color: #149dd8 !important;
    font-size: 13px;
    font-weight: 500;
    padding-left: 0;
}
.yr-mc-select {
    display: inline-block;
    margin-right: 20px;
}
.hr-top {
    border-top: 2px solid #ddd;
    padding-top: 20px;
}
.yr-manage-contact .fa-ellipsis-v:before {
    content: "" !important;
    background: url(/assets/media/icons/ellipsis-v-2.svg) !important;
    width: 8px;
    height: 25px;
    position: relative;
    display: block;
    background-repeat: no-repeat !important;
    background-size: 5px !important;
}
.fa-user:before {
    content: "" !important;
    background: url(/assets/media/icons/icon-user.svg) !important;
    width: 18px;
    height: 18px;
    position: relative;
    display: block;
    background-repeat: no-repeat !important;
    background-size: 18px !important;
}
.yr-manage-contact .btn.dropdown-toggle i {
    font-size: 12px;
    color: #696969;
    padding: 10px;
}
.yr-manage-contact table.table.yr-bucket-table .btn.dropdown-toggle{
padding-right: 0px;
}
.yr-manage-contact table.table.yr-bucket-table .btn.dropdown-toggle:after{
    content: inherit;
}
.yr-manage-contact .yr-shortBy{
    padding-left: 15px;
}
.yr-manage-contact .yr-shortBy .kt-checkbox > span {
    border: 2px solid #d1d7e2;
}
.yr-manageContacts-2 tr th{
    white-space: nowrap;
}
.yr-manageContacts-2 i.fa.fa-chevron-right.blue {
    margin-left: 8px;
}
.yr-nowrap{
    white-space: nowrap;
}

.yr-manageContacts-2 table.dataTable thead .sorting:before, .yr-manageContacts-2 table.dataTable thead .sorting_asc:before, .yr-manageContacts-2 table.dataTable thead .sorting_desc:before{
    bottom: 24px !important;
}
.yr-manageContacts-2 table.dataTable thead .sorting:after, .yr-manageContacts-2 table.dataTable thead .sorting_asc:after, .yr-manageContacts-2 table.dataTable thead .sorting_desc:after{
    bottom: 14px !important;
}
.yr-manageContacts-2 .dataTables_wrapper .dataTable th:first-child, .yr-manageContacts-2 .table tbody tr td:first-child {
    width: 30px !important;
    min-width: auto !important;
}
.yr-manageContacts-2 .table tbody tr td:first-child .kt-checkbox {
    width: 18px;
    height: 18px;
}
.yr-manage-contact2 .btn-outline-info.zy-outline {
    color: #159bd5;
    border-color: #159bd5;
    border: 2px solid;
}
.yr-manage-contact2 .dataTables_wrapper .dataTable th .kt-checkbox{
    margin-top: auto;
    margin-bottom: 14px;
}
.bck-btn a i {
    color: #1aadfb;
    margin-right: 6px;
}
.bck-btn a {
    font-size: 16px;
    color: #8c8a8a;
}
.yr-campaigns-model h4 {
    color: #1b8dca;
    font-size: 16px;
    margin-top: 30px;
}
.new-model-popup .yr-campaigns-model .modal-header {
    border-bottom: 1px solid #ddd;
}
.yr-text-area-1, .yr-text-area-2 {
    padding: 10px;
    border: 1px dotted #0783b4;
    border-radius: 5px;
}
.yr-text-area-2 {
    border: 1px solid #0783b4;
}
.yr-text-area-1 p, .yr-text-area-2 p{
    font-size: 12px;
}
.yr-email-s-line>span {
    margin-right: 20px;
}
.yr-email-s-line {
    display: flex;
    border: 1px solid #ddd;
    padding: 7px 10px;
    border-radius: 3px;
    line-height: 2;
    font-size: 12px;
}
.yr-email-s-line .dropdown-toggle.yr-managebtn{
    border: 0px;
    font-size: 12px;
}
.yr-email-s-line .dropdown-toggle::after{
    opacity: 1;
    top: 2px;
}
ul.column-box-area {
    list-style: none;
    border: 1px solid #ddd;
    padding: 8px;
    background: #f3f3f3;
    display: flex;
    flex: 1;
    border-radius: 3px !important;
}
ul.column-box-area li {
    border: 2px solid #999;
    flex: 1;
    height: 20px;
    background: #fff;
    border-left: 0px;
}
ul.column-box-area li:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-left: 2px solid;
}
ul.column-box-area li:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-right: 2px solid;
}

.yr-editor-icon span {
    margin-top: 8px;
}
.yr-editor-icon {
    width: 100%;
    border: 1px solid #f2f2f2;
    text-align: center;
    padding: 15px 5px 5px;
    margin: 10px 0;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yr-editor-drag-drop .card-header .card-title {
    color: #01c8a1 !important;
    font-weight: 600;
}
.yr-editor-drag-drop .card-header .card-title:after{
    color: #01c8a1 !important;
}
.email-customization .small-title {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.email-customization .right-action-btns .btn.btn-icon {
    height: 2.5rem;
    width: 2.5rem;
}
.email-customization .yr-chat-round-box.inner-box .box-head div {
    flex: 1;
}
.email-customization .right-action-btns {
    max-width: 83px;
    white-space: nowrap;
}
.email-customization .right-action-btns .btn.btn-icon:last-child {
    width: auto;
}
.yr-ep-icon{
    vertical-align: top;
    display:inline-block;
}
.yr-bucket-table td{
    color:black !important;
}
.rulesmarketing .add-gradient{
  display:block;
}
@media (max-width: 480px){
	.yr-ameplaybooks .nav-tabs.nav-tabs-line .nav-item {
    min-width: auto;
}
}

@media(max-width: 1440px){
    .width-fluid{
        max-width: 1280px !important
    }
}


@media(min-width: 1441px){
    .width-fluid{
        max-width: 90vw !important
    }
}




/* new css for left box design by aslam khan */
.zyra--left__boxes .box-head{
    align-items: flex-start;
}

.zyra--left__boxes .zyr-title{
    font-size: 10px;
}
.zyra--left__boxes .pre-number-box{
    font-size: 12px;
    width: 20px;
    height: 20px;
}
.zyra--left__boxes .body-text{
    max-width: 70%;
}

.zyra--left__boxes .body-text div h4{
    font-size: 11px;
}

.zyra--left__boxes .body-text div img{
    width: 16px;
}

.zyra--left__boxes .right-action-btns{
    max-width: 55px;
}

.zyra--left__boxes .right-action-btns .zyr-btn-icon{
    width: 1.5rem !important;
    height: 1.5rem !important;
}

i.large.icon{
    font-size: 1em !important;
}

.email-info-content{
    position: absolute;
    right: -25px;
    bottom: 6px;
    color: #06a1ac;
}

i.circular.icon{
    box-shadow: 0 0 0 0.1em rgb(43 185 164) inset !important;
}
.playbook-action-container{ 
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

button:disabled,
button.disabled {
    background: #ccc;
    color: #0006 !important;
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: none;
  }

  .disabled + .email-info-content.error{
      color: red;
  }

  .disabled + .email-info-content.error i{
    box-shadow: 0 0 0 0.1em red inset !important;
  }